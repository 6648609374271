import { analytics } from "@flare/analytics";

import type { FunnelAnswers } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";
import { FUNNEL_DATA_SESSION_STORAGE_KEY } from "@/services/storage";
import { getData, setData } from "@/services/storage/session-storage";

import type { Intent } from "./calculateLeadScore";
import {
  checkIntakeOfficeAvailability,
  CLOSE_HOUR,
  OPEN_HOUR,
} from "./checkIntakeOfficeAvailability";

type checkCmnEligibilityArgs = {
  leadIntent: Intent;
  leadScore: number;
};

export function checkCmnEligibility({
  leadIntent,
  leadScore,
}: checkCmnEligibilityArgs) {
  const funnelAnswers =
    (getData(FUNNEL_DATA_SESSION_STORAGE_KEY) as FunnelAnswers) || {};
  const isInBusinessHours = checkIntakeOfficeAvailability();
  const isEligible = leadIntent === "high" && isInBusinessHours;

  analytics.track(BiEventsNames.WebCmnEligibility, {
    is_eligible: isEligible,
    lead_intent: leadIntent,
    in_business_hours: isInBusinessHours,
    lead_score: leadScore,
    business_hour_open: OPEN_HOUR,
    business_hour_close: CLOSE_HOUR,
  });

  setData(FUNNEL_DATA_SESSION_STORAGE_KEY, {
    ...funnelAnswers,
    isEligibleForCmn: isEligible,
  });
  return isEligible;
}
