/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Custom Date scalar type. Serializes string, number and Date to Date. Parses number and string from client to Date */
  Date: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export enum AdministratorSubSteps {
  Admins = 'Admins',
  Cover = 'Cover',
  PersonalStatement = 'PersonalStatement',
  Summary = 'Summary'
}

export enum App {
  Lp = 'LP'
}

export type AppointmentPreferences = {
  courtAppearance?: InputMaybe<Scalars['Boolean']['input']>;
  criminalHistory?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  ethnicities?: InputMaybe<Array<Scalars['String']['input']>>;
  excludedProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  gender?: InputMaybe<DtoGender>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredDateRange?: InputMaybe<DateRange>;
  preferredProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  religions?: InputMaybe<Array<Scalars['String']['input']>>;
  urgentCases?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Attendee = {
  __typename?: 'Attendee';
  attendeeType: AttendeeType;
  id: Scalars['String']['output'];
};

export enum AttendeeType {
  Attorney = 'ATTORNEY',
  Client = 'CLIENT'
}

export type AttorneyData = {
  __typename?: 'AttorneyData';
  clientEmailResponsiveness?: Maybe<ClientEmailResponseTime>;
  education?: Maybe<Array<Education>>;
  gender?: Maybe<Gender>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Language>>;
  practiceAreas?: Maybe<Array<AttorneyPracticeArea>>;
  practiceAreasIds?: Maybe<Array<Scalars['String']['output']>>;
  preferredCommunicationMethods?: Maybe<Array<CommunicationMethod>>;
};

export type AttorneyPracticeArea = {
  __typename?: 'AttorneyPracticeArea';
  handlesCases: Scalars['Boolean']['output'];
  locations: Array<PracticeAreaLocation>;
  performsLSS: Scalars['Boolean']['output'];
  practiceArea: PracticeArea;
  practiceAreaId: Scalars['String']['output'];
  serviceExpertises: Array<ServiceExpertise>;
  servicePreferences: Array<ServicePreference>;
};

export type AttorneyReview = {
  __typename?: 'AttorneyReview';
  attorneyId: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reviewDate: Scalars['Date']['output'];
  score: Scalars['Float']['output'];
};

export type AttorneyReviewsFilter = {
  attorneyId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  scores?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum AuthError {
  UnknownError = 'UNKNOWN_ERROR'
}

export type AvailableSlot = {
  __typename?: 'AvailableSlot';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type BackupCharity = {
  __typename?: 'BackupCharity';
  address?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
};

export type BackupCharityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
};

export type BackupPerson = {
  __typename?: 'BackupPerson';
  name: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
  relation: Relation;
};

export type BackupPersonInput = {
  name: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
  relation: Relation;
};

export type Backups = {
  __typename?: 'Backups';
  backupCharity?: Maybe<Array<BackupCharity>>;
  backupPerson?: Maybe<Array<BackupPerson>>;
  otherBackup?: Maybe<OtherBackup>;
};

export type BackupsInput = {
  backupCharity?: InputMaybe<Array<BackupCharityInput>>;
  backupPerson?: InputMaybe<Array<BackupPersonInput>>;
  otherBackup?: InputMaybe<OtherBackupInput>;
};

export type BankOptionsResponse = {
  __typename?: 'BankOptionsResponse';
  persons?: Maybe<Array<Person>>;
};

export enum BasicInfoSubSteps {
  Address = 'Address',
  Cover = 'Cover',
  PersonalData = 'PersonalData',
  Summary = 'Summary'
}

export type BeneficiaryCharity = {
  __typename?: 'BeneficiaryCharity';
  address?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BeneficiaryCharityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type BeneficiaryPerson = {
  __typename?: 'BeneficiaryPerson';
  name: Scalars['String']['output'];
  relation: Relation;
};

export type BeneficiaryPersonInput = {
  name: Scalars['String']['input'];
  relation: Relation;
};

export type BookItMeeting = {
  __typename?: 'BookItMeeting';
  clientId?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  meetingId?: Maybe<Scalars['String']['output']>;
  meetingType?: Maybe<CalendarMeetingType>;
  notes?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status: BookItMeetingStatus;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum BookItMeetingStatus {
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Fulfilled = 'FULFILLED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED'
}

export enum BookeeMeetingType {
  EscalatedLss = 'ESCALATED_LSS',
  Lss = 'LSS',
  OneOnOne = 'ONE_ON_ONE'
}

export type BookitRequestResponse = {
  __typename?: 'BookitRequestResponse';
  id: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum Burial {
  Cremation = 'CREMATION',
  FamilyDecides = 'FAMILY_DECIDES',
  Ground = 'GROUND',
  Other = 'OTHER'
}

export enum CalendarMeetingType {
  CourtPrep = 'COURT_PREP',
  NewClientCall = 'NEW_CLIENT_CALL',
  Other = 'OTHER',
  Update = 'UPDATE'
}

export type CalendlyCanceledEventResponse = {
  __typename?: 'CalendlyCanceledEventResponse';
  errorMessage?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum CalendlyEventName {
  LssEvent = 'LSS_EVENT',
  StrategyReviewCall = 'STRATEGY_REVIEW_CALL'
}

export type CalendlyLinksResponse = {
  __typename?: 'CalendlyLinksResponse';
  cancelUrl: Scalars['String']['output'];
  isRescheduleDatesAvailable: Scalars['Boolean']['output'];
  rescheduleUrl: Scalars['String']['output'];
};

export type CancelCalendlyEventRequest = {
  calendlyEventId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type Case = {
  __typename?: 'Case';
  additionalFields?: Maybe<Scalars['JSON']['output']>;
  caseStartDate?: Maybe<Scalars['Date']['output']>;
  caseUpdate?: Maybe<GetCaseUpdateResponse>;
  createdAt: Scalars['Date']['output'];
  events?: Maybe<Array<CaseEvent>>;
  forms?: Maybe<Array<CaseForm>>;
  id: Scalars['String']['output'];
  legalTeam?: Maybe<CaseLegalTeam>;
  opposingParty?: Maybe<Scalars['String']['output']>;
  practiceArea: PracticeArea;
  practiceAreaId: Scalars['String']['output'];
  preClientEvents?: Maybe<Array<GetPreClientEventsResponse>>;
  repeats?: Maybe<Array<Repeat>>;
  services?: Maybe<Array<Service>>;
  status: CaseStatus;
  strategyReviewCallComplete?: Maybe<Scalars['Boolean']['output']>;
  strategyReviewCallCompleteDate?: Maybe<Scalars['Date']['output']>;
  userId: Scalars['String']['output'];
};

export type CaseEvent = {
  __typename?: 'CaseEvent';
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  users: Array<Scalars['String']['output']>;
};

export type CaseForm = {
  __typename?: 'CaseForm';
  name: FormName;
  saved?: Maybe<Scalars['Boolean']['output']>;
  submitted?: Maybe<Scalars['Boolean']['output']>;
  submittedAt?: Maybe<Scalars['Date']['output']>;
  uuid: Scalars['String']['output'];
};

export type CaseLegalTeam = {
  __typename?: 'CaseLegalTeam';
  caseManager?: Maybe<User>;
  paralegal?: Maybe<User>;
  responsibleAttorney?: Maybe<User>;
};

export enum CaseStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum CasesError {
  CaseNotFound = 'CASE_NOT_FOUND'
}

export type CasesResolverResponse = {
  __typename?: 'CasesResolverResponse';
  error?: Maybe<CasesError>;
  success: Scalars['Boolean']['output'];
};

export type CasesStatuses = {
  __typename?: 'CasesStatuses';
  accepted: Scalars['Boolean']['output'];
  pending: Scalars['Boolean']['output'];
  rejected: Scalars['Boolean']['output'];
};

export type CashGift = {
  __typename?: 'CashGift';
  amount: Scalars['Float']['output'];
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
};

export type CashGiftInput = {
  amount: Scalars['Float']['input'];
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
};

export enum Ceremony {
  FamilyDecides = 'FAMILY_DECIDES',
  Funeral = 'FUNERAL',
  FuneralAndMemorial = 'FUNERAL_AND_MEMORIAL',
  Memorial = 'MEMORIAL',
  NoCeremony = 'NO_CEREMONY',
  Other = 'OTHER'
}

export type Charity = {
  __typename?: 'Charity';
  address?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CharityInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum ClientEmailResponseTime {
  FortyEightToSeventyTwoHours = 'FORTY_EIGHT_TO_SEVENTY_TWO_HOURS',
  SameBusinessDay = 'SAME_BUSINESS_DAY',
  SameDay = 'SAME_DAY',
  TwentyFourToFortyEightHours = 'TWENTY_FOUR_TO_FORTY_EIGHT_HOURS'
}

export enum CommunicationMethod {
  Email = 'EMAIL',
  PhoneCall = 'PHONE_CALL',
  TextMessage = 'TEXT_MESSAGE',
  VideoConference = 'VIDEO_CONFERENCE'
}

export type CreateBookitRequestPayload = {
  appointmentPreferences?: InputMaybe<AppointmentPreferences>;
  competencies: Array<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerEmail: Scalars['String']['input'];
  customerFirstName?: InputMaybe<Scalars['String']['input']>;
  customerFlareId?: InputMaybe<Scalars['String']['input']>;
  customerLastName?: InputMaybe<Scalars['String']['input']>;
  fips?: InputMaybe<Scalars['String']['input']>;
  meetingType: BookeeMeetingType;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  practiceArea: PracticeAreaName;
  stateCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVisitorInput = {
  address?: InputMaybe<AddressInput>;
  anonymousId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  funnelAnswers?: InputMaybe<Scalars['JSON']['input']>;
  leadScore?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<PartialNameInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  utmData?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateWillFormStepsRequest = {
  administrator: WillAdministratorInfoInput;
  basicInformation: WillBasicInfoInput;
  family: WillFamilyInfoInput;
  funeralWishes: WillStepInfoInput;
  gifts: WillStepInfoInput;
  residuary: WillResiduaryInfoInput;
  review: WillReviewInfoInput;
};

export type CreateWillRequest = {
  administrators?: InputMaybe<Array<PersonInput>>;
  burial?: InputMaybe<Burial>;
  burialAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  ceremony?: InputMaybe<Ceremony>;
  ceremonyAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  childrenGuardians?: InputMaybe<Array<PersonInput>>;
  gifts?: InputMaybe<GiftsInput>;
  hasChildrenInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildrenUnder18?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartnerInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  personalData: WillPersonalDataInput;
  pets?: InputMaybe<Array<PetInput>>;
  petsFunds?: InputMaybe<Scalars['Float']['input']>;
  petsGuardians?: InputMaybe<Array<PersonInput>>;
  residuaryPlan?: InputMaybe<ResiduaryPlanInput>;
  shouldAddResiduaryPlanBackups?: InputMaybe<Scalars['Boolean']['input']>;
  statement?: InputMaybe<Scalars['String']['input']>;
  wishesAdministrators?: InputMaybe<Array<PersonInput>>;
};

export type CustomerData = {
  __typename?: 'CustomerData';
  consentForCreditCheck?: Maybe<Scalars['Boolean']['output']>;
  customerState?: Maybe<CustomerState>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
};

export type CustomerState = {
  __typename?: 'CustomerState';
  blockAllCommunications?: Maybe<Scalars['Boolean']['output']>;
  emailConfirmed?: Maybe<Scalars['Boolean']['output']>;
  firstEmailSent?: Maybe<Scalars['Boolean']['output']>;
  freezeData?: Maybe<FreezeData>;
  postBookingShown?: Maybe<Scalars['Boolean']['output']>;
  repeatFlowType?: Maybe<RepeatFlowType>;
  submittedForms?: Maybe<Scalars['Boolean']['output']>;
  visitedMyMarbleWeb?: Maybe<Scalars['Boolean']['output']>;
  visitedOnboardingWizard?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomersList = {
  __typename?: 'CustomersList';
  count: Scalars['Int']['output'];
  customers: Array<User>;
  pagination: Pagination;
};

export type CustomersListFilter = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum DataPointsSubmissionError {
  DataPointsSubmissionFailed = 'DATA_POINTS_SUBMISSION_FAILED'
}

export type DateRange = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type DigitalAsset = {
  __typename?: 'DigitalAsset';
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type DigitalAssetInput = {
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export type Document = {
  __typename?: 'Document';
  caseId: Scalars['String']['output'];
  customerMarbleId: Scalars['String']['output'];
  customerVisibility: Scalars['Boolean']['output'];
  documentType: DocumentType;
  documentTypeId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  s3UploadDate?: Maybe<Scalars['Date']['output']>;
  sourceApp: SourceApps;
};

export type DocumentDataPoint = {
  documentIds: Array<Scalars['String']['input']>;
  documentTypeId: Scalars['String']['input'];
};

export type DocumentType = {
  __typename?: 'DocumentType';
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  howToObtain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  importanceDescription: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DocumentUploadRequest = {
  caseId: Scalars['String']['input'];
  customerVisibility: Scalars['Boolean']['input'];
  documentTypeId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  sourceApp: SourceApps;
  uploaderMarbleId: Scalars['String']['input'];
};

export type DocumentUploadResponse = {
  __typename?: 'DocumentUploadResponse';
  document: Document;
  documentId: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type DocumentsDataPointsSubmissionInput = {
  attorneyId: Scalars['String']['input'];
  documentsDataPoints: Array<DocumentDataPoint>;
};

export enum DtoGender {
  Female = 'Female',
  Male = 'Male',
  Na = 'Na',
  Other = 'Other'
}

export type Education = {
  __typename?: 'Education';
  graduationYear?: Maybe<Scalars['Float']['output']>;
  schoolName: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  attendees: Array<Attendee>;
  bookeeEventId?: Maybe<Scalars['String']['output']>;
  bookeeMeetingId?: Maybe<Scalars['String']['output']>;
  calendlyEventId?: Maybe<Scalars['String']['output']>;
  caseId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
  isCancelled: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  syncInfo?: Maybe<SyncInfo>;
};

export enum EventType {
  CalendarEvent = 'CALENDAR_EVENT',
  Event = 'EVENT',
  LssEvent = 'LSS_EVENT'
}

export enum FamilySubSteps {
  Cover = 'Cover',
  Kids = 'Kids',
  KidsGuardian = 'KidsGuardian',
  MaritalStatus = 'MaritalStatus',
  Pets = 'Pets',
  Summary = 'Summary'
}

export enum FeedbackType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export enum FormName {
  ClientQuestionnaire = 'CLIENT_QUESTIONNAIRE',
  RepeatRequest = 'REPEAT_REQUEST'
}

export type FreezeData = {
  __typename?: 'FreezeData';
  frozenAt?: Maybe<Scalars['Date']['output']>;
  initiatedBy?: Maybe<Scalars['String']['output']>;
  isFrozen?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GenerateWillReponse = {
  __typename?: 'GenerateWillReponse';
  downloadUrl: Scalars['String']['output'];
};

export type GetAvailableSlotsRequest = {
  competencies: Array<Scalars['String']['input']>;
  fips?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  meetingType: BookeeMeetingType;
  minSlots?: InputMaybe<Scalars['Int']['input']>;
  practiceArea: PracticeAreaName;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type GetBookItMeetingSlotsInput = {
  from?: InputMaybe<Scalars['Date']['input']>;
  requestId: Scalars['String']['input'];
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type GetBookItMeetingsInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  meetingTypeId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<BookItMeetingStatus>>;
};

export type GetBookItMeetingsResponse = {
  __typename?: 'GetBookItMeetingsResponse';
  meetings: Array<BookItMeeting>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
};

export type GetCaseUpdateResponse = {
  __typename?: 'GetCaseUpdateResponse';
  caseUpdate?: Maybe<Scalars['String']['output']>;
  caseUpdateId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerMarbleId?: Maybe<Scalars['String']['output']>;
  feedbackCreatedAt?: Maybe<Scalars['String']['output']>;
  feedbackType?: Maybe<FeedbackType>;
  negativeFeedbackFreeText?: Maybe<Scalars['String']['output']>;
  negativeFeedbackReason?: Maybe<Scalars['String']['output']>;
};

export type GetPreClientEventsResponse = Event | LssEvent;

export type GetUserCasesInput = {
  statuses?: InputMaybe<Array<CaseStatus>>;
};

export type Gifts = {
  __typename?: 'Gifts';
  cashGifts?: Maybe<Array<CashGift>>;
  digitalAssets?: Maybe<Array<DigitalAsset>>;
  properties?: Maybe<Array<Property>>;
  specificItems?: Maybe<Array<SpecificItem>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type GiftsInput = {
  cashGifts?: InputMaybe<Array<CashGiftInput>>;
  digitalAssets?: InputMaybe<Array<DigitalAssetInput>>;
  properties?: InputMaybe<Array<PropertyInput>>;
  specificItems?: InputMaybe<Array<SpecificItemInput>>;
  vehicles?: InputMaybe<Array<VehicleInput>>;
};

export enum HashAlgorithm {
  Md5 = 'MD5',
  Sha256 = 'SHA256',
  Sha512 = 'SHA512'
}

export enum HashError {
  UnknownError = 'UNKNOWN_ERROR'
}

export type HashOptions = {
  algorithm?: InputMaybe<HashAlgorithm>;
  useSalt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HashRequest = {
  hashOptions?: InputMaybe<HashOptions>;
  input: Scalars['JSON']['input'];
};

export type HashResponse = {
  __typename?: 'HashResponse';
  error?: Maybe<HashError>;
  hash?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum Language {
  Abkhazian = 'ABKHAZIAN',
  Afar = 'AFAR',
  Afrikaans = 'AFRIKAANS',
  Akan = 'AKAN',
  Albanian = 'ALBANIAN',
  Amharic = 'AMHARIC',
  Arabic = 'ARABIC',
  Aragonese = 'ARAGONESE',
  Armenian = 'ARMENIAN',
  Assamese = 'ASSAMESE',
  Avaric = 'AVARIC',
  Avestan = 'AVESTAN',
  Aymara = 'AYMARA',
  Azerbaijani = 'AZERBAIJANI',
  Bambara = 'BAMBARA',
  Bashkir = 'BASHKIR',
  Basque = 'BASQUE',
  Belarusian = 'BELARUSIAN',
  Bengali = 'BENGALI',
  Bislama = 'BISLAMA',
  Bosnian = 'BOSNIAN',
  Breton = 'BRETON',
  Bulgarian = 'BULGARIAN',
  Burmese = 'BURMESE',
  Catalan = 'CATALAN',
  CentralKhmer = 'CENTRAL_KHMER',
  Chamorro = 'CHAMORRO',
  Chechen = 'CHECHEN',
  Chichewa = 'CHICHEWA',
  Chinese = 'CHINESE',
  ChurchSlavonic = 'CHURCH_SLAVONIC',
  Chuvash = 'CHUVASH',
  Cornish = 'CORNISH',
  Corsican = 'CORSICAN',
  Cree = 'CREE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Divehi = 'DIVEHI',
  Dutch = 'DUTCH',
  Dzongkha = 'DZONGKHA',
  English = 'ENGLISH',
  Esperanto = 'ESPERANTO',
  Estonian = 'ESTONIAN',
  Ewe = 'EWE',
  Faroese = 'FAROESE',
  Fijian = 'FIJIAN',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  Fulah = 'FULAH',
  Gaelic = 'GAELIC',
  Galician = 'GALICIAN',
  Ganda = 'GANDA',
  Georgian = 'GEORGIAN',
  German = 'GERMAN',
  Greek = 'GREEK',
  Guarani = 'GUARANI',
  Gujarati = 'GUJARATI',
  Haitian = 'HAITIAN',
  Hausa = 'HAUSA',
  Hebrew = 'HEBREW',
  Herero = 'HERERO',
  Hindi = 'HINDI',
  HiriMotu = 'HIRI_MOTU',
  Hungarian = 'HUNGARIAN',
  Icelandic = 'ICELANDIC',
  Ido = 'IDO',
  Igbo = 'IGBO',
  Indonesian = 'INDONESIAN',
  Interlingua = 'INTERLINGUA',
  Interlingue = 'INTERLINGUE',
  Inuktitut = 'INUKTITUT',
  Inupiaq = 'INUPIAQ',
  Irish = 'IRISH',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Javanese = 'JAVANESE',
  Kalaallisut = 'KALAALLISUT',
  Kannada = 'KANNADA',
  Kanuri = 'KANURI',
  Kashmiri = 'KASHMIRI',
  Kazakh = 'KAZAKH',
  Kikuyu = 'KIKUYU',
  Kinyarwanda = 'KINYARWANDA',
  Kirghiz = 'KIRGHIZ',
  Komi = 'KOMI',
  Kongo = 'KONGO',
  Korean = 'KOREAN',
  Kuanyama = 'KUANYAMA',
  Kurdish = 'KURDISH',
  Lao = 'LAO',
  Latin = 'LATIN',
  Latvian = 'LATVIAN',
  Limburgan = 'LIMBURGAN',
  Lingala = 'LINGALA',
  Lithuanian = 'LITHUANIAN',
  LubaKatanga = 'LUBA_KATANGA',
  Luxembourgish = 'LUXEMBOURGISH',
  Macedonian = 'MACEDONIAN',
  Malagasy = 'MALAGASY',
  Malay = 'MALAY',
  Malayalam = 'MALAYALAM',
  Maltese = 'MALTESE',
  Manx = 'MANX',
  Maori = 'MAORI',
  Marathi = 'MARATHI',
  Marshallese = 'MARSHALLESE',
  Mongolian = 'MONGOLIAN',
  Nauru = 'NAURU',
  Navajo = 'NAVAJO',
  Ndonga = 'NDONGA',
  Nepali = 'NEPALI',
  NorthernSami = 'NORTHERN_SAMI',
  NorthNdebele = 'NORTH_NDEBELE',
  Norwegian = 'NORWEGIAN',
  NorwegianBokmal = 'NORWEGIAN_BOKMAL',
  NorwegianNynorsk = 'NORWEGIAN_NYNORSK',
  Occitan = 'OCCITAN',
  Ojibwa = 'OJIBWA',
  Oriya = 'ORIYA',
  Oromo = 'OROMO',
  Ossetian = 'OSSETIAN',
  Pali = 'PALI',
  Pashto = 'PASHTO',
  Persian = 'PERSIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Punjabi = 'PUNJABI',
  Quechua = 'QUECHUA',
  Romanian = 'ROMANIAN',
  Romansh = 'ROMANSH',
  Rundi = 'RUNDI',
  Russian = 'RUSSIAN',
  Samoan = 'SAMOAN',
  Sango = 'SANGO',
  Sanskrit = 'SANSKRIT',
  Sardinian = 'SARDINIAN',
  Serbian = 'SERBIAN',
  Shona = 'SHONA',
  SichuanYi = 'SICHUAN_YI',
  Sindhi = 'SINDHI',
  Sinhala = 'SINHALA',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  Somali = 'SOMALI',
  SouthernSotho = 'SOUTHERN_SOTHO',
  SouthNdebele = 'SOUTH_NDEBELE',
  Spanish = 'SPANISH',
  Sundanese = 'SUNDANESE',
  Swahili = 'SWAHILI',
  Swati = 'SWATI',
  Swedish = 'SWEDISH',
  Tagalog = 'TAGALOG',
  Tahitian = 'TAHITIAN',
  Tajik = 'TAJIK',
  Tamil = 'TAMIL',
  Tatar = 'TATAR',
  Telugu = 'TELUGU',
  Thai = 'THAI',
  Tibetan = 'TIBETAN',
  Tigrinya = 'TIGRINYA',
  Tonga = 'TONGA',
  Tsonga = 'TSONGA',
  Tswana = 'TSWANA',
  Turkish = 'TURKISH',
  Turkmen = 'TURKMEN',
  Twi = 'TWI',
  Uighur = 'UIGHUR',
  Ukrainian = 'UKRAINIAN',
  Urdu = 'URDU',
  Uzbek = 'UZBEK',
  Venda = 'VENDA',
  Vietnamese = 'VIETNAMESE',
  Volapuk = 'VOLAPUK',
  Walloon = 'WALLOON',
  Welsh = 'WELSH',
  WesternFrisian = 'WESTERN_FRISIAN',
  Wolof = 'WOLOF',
  Xhosa = 'XHOSA',
  Yiddish = 'YIDDISH',
  Yoruba = 'YORUBA',
  Zhuang = 'ZHUANG',
  Zulu = 'ZULU'
}

export type LegalTeamMember = {
  __typename?: 'LegalTeamMember';
  role: LegalTeamMemberRole;
  userId: Scalars['String']['output'];
};

export enum LegalTeamMemberRole {
  CaseManager = 'CASE_MANAGER',
  Paralegal = 'PARALEGAL',
  ResponsibleAttorney = 'RESPONSIBLE_ATTORNEY'
}

export type Location = {
  __typename?: 'Location';
  county?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

export type LoginByEmailRequest = {
  email: Scalars['String']['input'];
  isOtpLogin?: InputMaybe<Scalars['Boolean']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LoginByPhoneRequest = {
  phone: Scalars['String']['input'];
};

export type LoginByTokenRequest = {
  token: Scalars['String']['input'];
};

export type LoginByTokenResponse = {
  __typename?: 'LoginByTokenResponse';
  error?: Maybe<LoginError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum LoginError {
  UnknownError = 'UNKNOWN_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
  WrongCodeVerification = 'WRONG_CODE_VERIFICATION'
}

export type LoginImpersonateRequest = {
  email: Scalars['String']['input'];
  impersonationRoles?: InputMaybe<Array<Role>>;
};

export type LoginImpersonateResponse = {
  __typename?: 'LoginImpersonateResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  error?: Maybe<LoginError>;
  success: Scalars['Boolean']['output'];
};

export type LssEvent = {
  __typename?: 'LssEvent';
  attendees: Array<Attendee>;
  bookeeEventId?: Maybe<Scalars['String']['output']>;
  bookeeMeetingId?: Maybe<Scalars['String']['output']>;
  calendlyEventId?: Maybe<Scalars['String']['output']>;
  caseId: Scalars['String']['output'];
  data: LssEventData;
  endDate: Scalars['Date']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
  isCancelled: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  startDate: Scalars['Date']['output'];
  syncInfo?: Maybe<SyncInfo>;
};

export type LssEventData = {
  __typename?: 'LssEventData';
  lssStatus: LssStatus;
};

export enum LssStatus {
  Completed = 'COMPLETED',
  CompletedNoShow = 'COMPLETED_NO_SHOW',
  Created = 'CREATED',
  Rejected = 'REJECTED'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelCalendlyScheduledEvent: CalendlyCanceledEventResponse;
  createBookitRequest: BookitRequestResponse;
  createVisitor: Visitor;
  createWillDataPoints: WillDataPoints;
  createWillFormSteps?: Maybe<WillFormSteps>;
  flagSubmittedQuestionnaire: CasesResolverResponse;
  flagVisitedMyMarbleWeb: UsersResolverResponse;
  flagVisitedOnboardingWizard: UsersResolverResponse;
  generateSingleUseSchedulingUrl?: Maybe<Scalars['String']['output']>;
  generateWillPdf?: Maybe<GenerateWillReponse>;
  hash: HashResponse;
  loginByEmail: LoginResponse;
  loginByPhone: LoginResponse;
  loginByToken: LoginByTokenResponse;
  loginImpersonate: LoginImpersonateResponse;
  patchWillDataPoints?: Maybe<WillDataPoints>;
  predict: PredictResponse;
  refreshToken: RefreshTokenResponse;
  registerCaseUpdateFeedback: CasesResolverResponse;
  registerDevice: Scalars['Boolean']['output'];
  registerUserSeenUpdate: CasesResolverResponse;
  removeDocumentCustomerVisibility: UpdateResponse;
  renameDocument: UpdateResponse;
  requestDocumentUpload: DocumentUploadResponse;
  sendSupportMsg?: Maybe<SendSupportMsgResponse>;
  submitDocumentsDataPoints: SubmitDocumentsDataPointsResponse;
  submitRepeatSupportRequest: SubmitRepeatSupportRequestResponse;
  submitUnavailableDocument: SubmitUnavailableDocumentResponse;
  updateRepeat: Repeat;
  updateWillFormSteps?: Maybe<UpdateWillFormSteps>;
  verifyOTP: VerifyOtpResponse;
};


export type MutationCancelCalendlyScheduledEventArgs = {
  payload: CancelCalendlyEventRequest;
};


export type MutationCreateBookitRequestArgs = {
  payload: CreateBookitRequestPayload;
};


export type MutationCreateVisitorArgs = {
  newVisitor: CreateVisitorInput;
};


export type MutationCreateWillDataPointsArgs = {
  payload: CreateWillRequest;
};


export type MutationCreateWillFormStepsArgs = {
  payload: CreateWillFormStepsRequest;
};


export type MutationFlagSubmittedQuestionnaireArgs = {
  caseId: Scalars['String']['input'];
};


export type MutationGenerateSingleUseSchedulingUrlArgs = {
  payload: ScheduleLinkRequest;
};


export type MutationGenerateWillPdfArgs = {
  willId: Scalars['String']['input'];
};


export type MutationHashArgs = {
  request: HashRequest;
};


export type MutationLoginByEmailArgs = {
  loginByEmailRequest: LoginByEmailRequest;
};


export type MutationLoginByPhoneArgs = {
  loginByPhoneRequest: LoginByPhoneRequest;
};


export type MutationLoginByTokenArgs = {
  loginByTokenRequest: LoginByTokenRequest;
};


export type MutationLoginImpersonateArgs = {
  loginImpersonateRequest: LoginImpersonateRequest;
};


export type MutationPatchWillDataPointsArgs = {
  payload: PatchWillRequest;
};


export type MutationPredictArgs = {
  payload: PredictRequest;
};


export type MutationRefreshTokenArgs = {
  refreshTokenRequest: RefreshTokenRequest;
};


export type MutationRegisterCaseUpdateFeedbackArgs = {
  input: RegisterCaseUpdateFeedbackInput;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationRegisterUserSeenUpdateArgs = {
  caseUpdateId: Scalars['String']['input'];
};


export type MutationRemoveDocumentCustomerVisibilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationRenameDocumentArgs = {
  id: Scalars['String']['input'];
  newFileName: Scalars['String']['input'];
};


export type MutationRequestDocumentUploadArgs = {
  payload: DocumentUploadRequest;
};


export type MutationSendSupportMsgArgs = {
  msg: Scalars['String']['input'];
};


export type MutationSubmitDocumentsDataPointsArgs = {
  input: DocumentsDataPointsSubmissionInput;
};


export type MutationSubmitRepeatSupportRequestArgs = {
  input: RepeatSupportRequestInput;
};


export type MutationSubmitUnavailableDocumentArgs = {
  input: SubmitUnavailableDocumentInput;
};


export type MutationUpdateRepeatArgs = {
  payload: UpdateRepeatRequest;
  repeatId: Scalars['String']['input'];
};


export type MutationUpdateWillFormStepsArgs = {
  payload: UpdateWillFormStepsRequest;
};


export type MutationVerifyOtpArgs = {
  verifyOTPRequest: VerifyOtpRequest;
};

export type Name = {
  __typename?: 'Name';
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
  middle?: Maybe<Scalars['String']['output']>;
};

export type NameInput = {
  first: Scalars['String']['input'];
  last: Scalars['String']['input'];
  middle?: InputMaybe<Scalars['String']['input']>;
};

export type OtherBackup = {
  __typename?: 'OtherBackup';
  optionType: OtherBackupType;
};

export type OtherBackupInput = {
  optionType: OtherBackupType;
};

export enum OtherBackupType {
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  ListedInInheritance = 'LISTED_IN_INHERITANCE'
}

export type Pagination = {
  __typename?: 'Pagination';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PartialName = {
  __typename?: 'PartialName';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
  middle?: Maybe<Scalars['String']['output']>;
};

export type PartialNameInput = {
  first?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['String']['input']>;
  middle?: InputMaybe<Scalars['String']['input']>;
};

export type PatchWillRequest = {
  administrators?: InputMaybe<Array<PersonInput>>;
  burial?: InputMaybe<Burial>;
  burialAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  ceremony?: InputMaybe<Ceremony>;
  ceremonyAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  childrenGuardians?: InputMaybe<Array<PersonInput>>;
  gifts?: InputMaybe<GiftsInput>;
  hasChildrenInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildrenUnder18?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartnerInheritance?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  personalData?: InputMaybe<WillPersonalDataPatchInput>;
  pets?: InputMaybe<Array<PetInput>>;
  petsFunds?: InputMaybe<Scalars['Float']['input']>;
  petsGuardians?: InputMaybe<Array<PersonInput>>;
  residuaryPlan?: InputMaybe<ResiduaryPlanInput>;
  shouldAddResiduaryPlanBackups?: InputMaybe<Scalars['Boolean']['input']>;
  statement?: InputMaybe<Scalars['String']['input']>;
  wishesAdministrators?: InputMaybe<Array<PersonInput>>;
};

export type Person = {
  __typename?: 'Person';
  name: Scalars['String']['output'];
  relation: Relation;
};

export type PersonInput = {
  name: Scalars['String']['input'];
  relation: Relation;
};

export enum PersonalStatues {
  DomesticPartnership = 'DOMESTIC_PARTNERSHIP',
  InTheProgressOfADivorce = 'IN_THE_PROGRESS_OF_A_DIVORCE',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type Pet = {
  __typename?: 'Pet';
  name: Scalars['String']['output'];
  species: Species;
};

export type PetInput = {
  name: Scalars['String']['input'];
  species: Species;
};

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type PracticeArea = {
  __typename?: 'PracticeArea';
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type PracticeAreaLocation = {
  __typename?: 'PracticeAreaLocation';
  fips: Scalars['String']['output'];
  stateId: Scalars['String']['output'];
};

export enum PracticeAreaName {
  Family = 'FAMILY',
  Immigration = 'IMMIGRATION'
}

export type PredictRequest = {
  features: Scalars['JSON']['input'];
};

export type PredictResponse = {
  __typename?: 'PredictResponse';
  probability: Scalars['Float']['output'];
};

export type Property = {
  __typename?: 'Property';
  address: WillsAddress;
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type PropertyInput = {
  address: WillsAddressInput;
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  getAllPersonValuesByWillId?: Maybe<BankOptionsResponse>;
  getAttorneyReviews?: Maybe<Array<AttorneyReview>>;
  getAuthenticatedUser: UsersResolverResponse;
  getAvailableSlots: Array<AvailableSlot>;
  getBookItMeetingSlots: Array<AvailableSlot>;
  getBookItMeetings: GetBookItMeetingsResponse;
  getCalendlyLinks: CalendlyLinksResponse;
  getCaseById?: Maybe<Case>;
  getCasesByUserId?: Maybe<Array<Case>>;
  getCustomer: User;
  getCustomerCases?: Maybe<Array<Case>>;
  getCustomerDocuments: Array<Document>;
  getDocumentDownloadUrl: Scalars['String']['output'];
  getDocumentsByCaseId: Array<Document>;
  getRequiredDocuments: RequiredDocumentsResponse;
  getScheduledEventData: ScheduledEventResponse;
  getServingTaskByCaseId?: Maybe<Array<ServingTask>>;
  getStaticCaseUpdate?: Maybe<GetCaseUpdateResponse>;
  getSupportedUploadDocumentTypes: Array<DocumentType>;
  getUserCasesByStatus?: Maybe<Array<Case>>;
  getVisitorById: Visitor;
  getVisitorStatus: VisitorStatus;
  getWillDataById?: Maybe<WillDataPoints>;
  getWillFormStepsByUser?: Maybe<Array<WillFormSteps>>;
  getWillsDataByUserId: Array<WillDataPoints>;
  hasAvailableSlots: Scalars['Boolean']['output'];
  listCustomers: CustomersList;
};


export type QueryGetAllPersonValuesByWillIdArgs = {
  willId: Scalars['String']['input'];
};


export type QueryGetAttorneyReviewsArgs = {
  filter: AttorneyReviewsFilter;
  sort?: InputMaybe<SingleFieldSort>;
};


export type QueryGetAvailableSlotsArgs = {
  payload: GetAvailableSlotsRequest;
};


export type QueryGetBookItMeetingSlotsArgs = {
  input: GetBookItMeetingSlotsInput;
};


export type QueryGetBookItMeetingsArgs = {
  getBookItMeetingsInput: GetBookItMeetingsInput;
};


export type QueryGetCalendlyLinksArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryGetCaseByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCasesByUserIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCustomerArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDocumentDownloadUrlArgs = {
  documentId: Scalars['String']['input'];
};


export type QueryGetDocumentsByCaseIdArgs = {
  caseId: Scalars['String']['input'];
};


export type QueryGetScheduledEventDataArgs = {
  scheduledEventUri: Scalars['String']['input'];
};


export type QueryGetServingTaskByCaseIdArgs = {
  caseId: Scalars['String']['input'];
};


export type QueryGetStaticCaseUpdateArgs = {
  input: StaticCaseUpdateInput;
};


export type QueryGetUserCasesByStatusArgs = {
  filter?: InputMaybe<GetUserCasesInput>;
};


export type QueryGetVisitorByIdArgs = {
  visitorId: Scalars['String']['input'];
};


export type QueryGetVisitorStatusArgs = {
  visitorStatusInput: VisitorStatusInputReq;
};


export type QueryGetWillDataByIdArgs = {
  willId: Scalars['String']['input'];
};


export type QueryHasAvailableSlotsArgs = {
  payload: GetAvailableSlotsRequest;
};


export type QueryListCustomersArgs = {
  filter?: InputMaybe<CustomersListFilter>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SingleFieldSort>;
};

export type RefreshTokenRequest = {
  token: Scalars['String']['input'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  error?: Maybe<AuthError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type RegisterCaseUpdateFeedbackInput = {
  caseUpdateId: Scalars['String']['input'];
  feedbackType: FeedbackType;
  negativeFeedbackFreeText?: InputMaybe<Scalars['String']['input']>;
  negativeFeedbackReason?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterDeviceInput = {
  app: App;
  platform: PlatformType;
  token: Scalars['String']['input'];
};

export type RejectedModel = {
  __typename?: 'RejectedModel';
  rejectedNotes?: Maybe<Scalars['String']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
};

export enum Relation {
  Child = 'CHILD',
  Friend = 'FRIEND',
  Other = 'OTHER',
  Parent = 'PARENT',
  Partner = 'PARTNER',
  Relative = 'RELATIVE',
  Sibling = 'SIBLING'
}

export type Repeat = {
  __typename?: 'Repeat';
  attorneyId: Scalars['String']['output'];
  attorneyNotes?: Maybe<Scalars['String']['output']>;
  caseId: Scalars['String']['output'];
  changedReason?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  isNoTouchFlowEligible?: Maybe<Scalars['Boolean']['output']>;
  rejected?: Maybe<RejectedModel>;
  services: RepeatService;
  status?: Maybe<RepeatStatus>;
};

export enum RepeatFlowType {
  NoTouch = 'NO_TOUCH',
  Touch = 'TOUCH'
}

export type RepeatService = {
  __typename?: 'RepeatService';
  addedServiceIds: Array<Scalars['String']['output']>;
  removedServicesIds: Array<Scalars['String']['output']>;
};

export enum RepeatStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Open = 'OPEN'
}

export enum RepeatSupportRequestError {
  RepeatSupportRequestNotSent = 'REPEAT_SUPPORT_REQUEST_NOT_SENT'
}

export type RepeatSupportRequestInput = {
  message: Scalars['String']['input'];
  repeatId: Scalars['String']['input'];
  supportRequestType: RepeatSupportRequestTypeEnum;
};

export enum RepeatSupportRequestTypeEnum {
  Other = 'OTHER',
  Payment = 'PAYMENT',
  Services = 'SERVICES'
}

export type RequiredDocumentsResponse = {
  __typename?: 'RequiredDocumentsResponse';
  documentTypes: Array<DocumentType>;
  lssIds: Array<Scalars['String']['output']>;
  repeatIds: Array<Scalars['String']['output']>;
};

export type ResiduaryPlan = {
  __typename?: 'ResiduaryPlan';
  charities?: Maybe<Array<ResiduaryPlanCharities>>;
  persons?: Maybe<Array<ResiduaryPlanPersons>>;
};

export type ResiduaryPlanCharities = {
  __typename?: 'ResiduaryPlanCharities';
  backups?: Maybe<Backups>;
  charity: Charity;
  percent: Scalars['Float']['output'];
};

export type ResiduaryPlanCharitiesInput = {
  backups?: InputMaybe<BackupsInput>;
  charity: CharityInput;
  percent: Scalars['Float']['input'];
};

export type ResiduaryPlanInput = {
  charities?: InputMaybe<Array<ResiduaryPlanCharitiesInput>>;
  persons?: InputMaybe<Array<ResiduaryPlanPersonsInput>>;
};

export type ResiduaryPlanPersons = {
  __typename?: 'ResiduaryPlanPersons';
  backups?: Maybe<Backups>;
  percent: Scalars['Float']['output'];
  person: Person;
};

export type ResiduaryPlanPersonsInput = {
  backups?: InputMaybe<BackupsInput>;
  percent: Scalars['Float']['input'];
  person: PersonInput;
};

export enum ResiduarySubSteps {
  Backups = 'Backups',
  Cover = 'Cover',
  DesignateBackups = 'DesignateBackups',
  Plan = 'Plan',
  Summary = 'Summary'
}

export enum ReviewSubSteps {
  Main = 'Main'
}

export enum Role {
  Admin = 'ADMIN',
  Attorney = 'ATTORNEY',
  Customer = 'CUSTOMER',
  ExternalAttorney = 'EXTERNAL_ATTORNEY',
  ExternalParalegal = 'EXTERNAL_PARALEGAL',
  Paralegal = 'PARALEGAL'
}

export type ScheduleLinkRequest = {
  eventName: CalendlyEventName;
  inviteeId: Scalars['String']['input'];
  inviterId: Scalars['String']['input'];
};

export type ScheduledEventResponse = {
  __typename?: 'ScheduledEventResponse';
  endTime: Scalars['String']['output'];
  eventName: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type Service = {
  __typename?: 'Service';
  caseId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalTeam: Array<LegalTeamMember>;
  location: Location;
  name: Scalars['String']['output'];
  practiceAreaId: Scalars['String']['output'];
  serviceType?: Maybe<ServiceType>;
  serviceTypeId: Scalars['String']['output'];
  status: ServiceStatus;
  userId: Scalars['String']['output'];
};

export enum ServiceAttitude {
  Dislike = 'DISLIKE',
  NoPrefrence = 'NO_PREFRENCE',
  Prefer = 'PREFER'
}

export type ServiceExpertise = {
  __typename?: 'ServiceExpertise';
  attitude?: Maybe<ServiceAttitude>;
  key: Scalars['String']['output'];
};

export type ServicePreference = {
  __typename?: 'ServicePreference';
  key: Scalars['String']['output'];
};

export enum ServiceStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Disengaged = 'DISENGAGED',
  Open = 'OPEN',
  PartiallyRendered = 'PARTIALLY_RENDERED',
  Pending = 'PENDING'
}

export type ServiceType = {
  __typename?: 'ServiceType';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionForClient?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  practiceAreaId: Scalars['String']['output'];
  stepsForClient?: Maybe<Array<StepsForClientDto>>;
  suggestedNextSteps?: Maybe<Scalars['String']['output']>;
  whatHappensNext?: Maybe<Scalars['String']['output']>;
  whatIsIncluded?: Maybe<Array<Scalars['String']['output']>>;
  whatIsNotIncluded?: Maybe<Scalars['String']['output']>;
};

export type ServingAttempt = {
  __typename?: 'ServingAttempt';
  address: Scalars['String']['output'];
  attemptDate: Scalars['Date']['output'];
  attemptId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum ServingStatus {
  Assigned = 'ASSIGNED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  PendingFiling = 'PENDING_FILING',
  PendingNotary = 'PENDING_NOTARY',
  PendingRating = 'PENDING_RATING',
  Reassigned = 'REASSIGNED',
  Unassigned = 'UNASSIGNED',
  Unknown = 'UNKNOWN'
}

export type ServingTask = {
  __typename?: 'ServingTask';
  attempts: Array<ServingAttempt>;
  status: ServingStatus;
};

export type SingleFieldSort = {
  field: Scalars['String']['input'];
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SourceApps {
  AttorneysWebApp = 'ATTORNEYS_WEB_APP',
  EmailProxy = 'EMAIL_PROXY',
  Flarex = 'FLAREX',
  GoogleDrive = 'GOOGLE_DRIVE',
  Lawmatics = 'LAWMATICS',
  MyMarbleMobile = 'MY_MARBLE_MOBILE',
  MyMarbleWeb = 'MY_MARBLE_WEB',
  Netsuite = 'NETSUITE',
  Pactsafe = 'PACTSAFE'
}

export enum Species {
  Bird = 'BIRD',
  Cat = 'CAT',
  Dog = 'DOG',
  Fish = 'FISH',
  Other = 'OTHER'
}

export type SpecificItem = {
  __typename?: 'SpecificItem';
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type SpecificItemInput = {
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export enum StaticCaseUpdateEnum {
  ASrcC = 'A_SRC_C',
  ASrcIp = 'A_SRC_IP',
  ASrcNs = 'A_SRC_NS',
  BSrcC = 'B_SRC_C',
  BSrcIp = 'B_SRC_IP',
  BSrcNs = 'B_SRC_NS'
}

export type StaticCaseUpdateInput = {
  step?: InputMaybe<StaticCaseUpdateEnum>;
};

export type StepsForClientDto = {
  __typename?: 'StepsForClientDto';
  description?: Maybe<Scalars['String']['output']>;
  estimation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stepNumber: Scalars['Float']['output'];
};

export type SubmitDocumentsDataPointsResponse = {
  __typename?: 'SubmitDocumentsDataPointsResponse';
  error?: Maybe<DataPointsSubmissionError>;
  success: Scalars['Boolean']['output'];
};

export type SubmitRepeatSupportRequestResponse = {
  __typename?: 'SubmitRepeatSupportRequestResponse';
  error?: Maybe<RepeatSupportRequestError>;
  success: Scalars['Boolean']['output'];
};

export enum SubmitUnavailableDocumentError {
  SubmitUnavailableDocumentError = 'SUBMIT_UNAVAILABLE_DOCUMENT_ERROR'
}

export type SubmitUnavailableDocumentInput = {
  attorneyId: Scalars['String']['input'];
  documentTypeId: Scalars['String']['input'];
  unavailableDocumentReason: Scalars['String']['input'];
};

export type SubmitUnavailableDocumentResponse = {
  __typename?: 'SubmitUnavailableDocumentResponse';
  error?: Maybe<SubmitUnavailableDocumentError>;
  success: Scalars['Boolean']['output'];
};

export type SyncInfo = {
  __typename?: 'SyncInfo';
  salesforceOpportunityId: Scalars['String']['output'];
};

export enum UpdateDocumentError {
  DocumentNotFound = 'DOCUMENT_NOT_FOUND',
  UnknownError = 'UNKNOWN_ERROR'
}

export type UpdateRepeatRequest = {
  status: RepeatStatus;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  error?: Maybe<UpdateDocumentError>;
  success: Scalars['Boolean']['output'];
};

export type UpdateWillFormSteps = {
  __typename?: 'UpdateWillFormSteps';
  administrator?: Maybe<WillAdministratorInfo>;
  basicInformation?: Maybe<WillBasicInfo>;
  family?: Maybe<WillFamilyInfo>;
  funeralWishes?: Maybe<WillStepInfo>;
  gifts?: Maybe<WillStepInfo>;
  id: Scalars['String']['output'];
  residuary?: Maybe<WillResiduaryInfo>;
  review?: Maybe<WillReviewInfo>;
  userId: Scalars['String']['output'];
};

export type UpdateWillFormStepsRequest = {
  administrator?: InputMaybe<WillAdministratorInfoInput>;
  basicInformation?: InputMaybe<WillBasicInfoInput>;
  family?: InputMaybe<WillFamilyInfoInput>;
  funeralWishes?: InputMaybe<WillStepInfoInput>;
  gifts?: InputMaybe<WillStepInfoInput>;
  id: Scalars['String']['input'];
  residuary?: InputMaybe<WillResiduaryInfoInput>;
  review?: InputMaybe<WillReviewInfoInput>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  attorneyData?: Maybe<AttorneyData>;
  bookItId?: Maybe<Scalars['String']['output']>;
  casesStatuses: CasesStatuses;
  customerData?: Maybe<CustomerData>;
  email: Scalars['String']['output'];
  emailAlias?: Maybe<Scalars['String']['output']>;
  hasCase?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  marbleId: Scalars['String']['output'];
  name: Name;
  phone?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
};

export enum UsersError {
  UnknownError = 'UNKNOWN_ERROR',
  UserIdRequiredError = 'USER_ID_REQUIRED_ERROR',
  UserNotFound = 'USER_NOT_FOUND',
  WrongCodeVerification = 'WRONG_CODE_VERIFICATION'
}

export type UsersResolverResponse = {
  __typename?: 'UsersResolverResponse';
  error?: Maybe<UsersError>;
  success: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  beneficiaryCharity?: Maybe<BeneficiaryCharity>;
  beneficiaryPerson?: Maybe<BeneficiaryPerson>;
  description: Scalars['String']['output'];
};

export type VehicleInput = {
  beneficiaryCharity?: InputMaybe<BeneficiaryCharityInput>;
  beneficiaryPerson?: InputMaybe<BeneficiaryPersonInput>;
  description: Scalars['String']['input'];
};

export type VerifyOtpRequest = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOTPResponse';
  error?: Maybe<LoginError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type Visitor = {
  __typename?: 'Visitor';
  address?: Maybe<Address>;
  anonymousId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  funnelAnswers?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  leadScore?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<PartialName>;
  phone?: Maybe<Scalars['String']['output']>;
  utmData?: Maybe<Scalars['JSON']['output']>;
};

export type VisitorStatus = {
  __typename?: 'VisitorStatus';
  statusCode: VisitorStatusCode;
};

export enum VisitorStatusCode {
  Cnf3 = 'CNF3',
  Csi2 = 'CSI2',
  Cur4 = 'CUR4',
  Elg1 = 'ELG1',
  Error = 'ERROR'
}

export type VisitorStatusInputReq = {
  answers: Scalars['JSON']['input'];
};

export type WillAdministratorInfo = {
  __typename?: 'WillAdministratorInfo';
  lastActiveSubStep: AdministratorSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillAdministratorInfoInput = {
  lastActiveSubStep: AdministratorSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillBasicInfo = {
  __typename?: 'WillBasicInfo';
  lastActiveSubStep: BasicInfoSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillBasicInfoInput = {
  lastActiveSubStep: BasicInfoSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillDataPoints = {
  __typename?: 'WillDataPoints';
  administrators?: Maybe<Array<Person>>;
  burial?: Maybe<Burial>;
  burialAdditionalInfo?: Maybe<Scalars['String']['output']>;
  ceremony?: Maybe<Ceremony>;
  ceremonyAdditionalInfo?: Maybe<Scalars['String']['output']>;
  childrenGuardians?: Maybe<Array<Person>>;
  gifts?: Maybe<Gifts>;
  hasChildrenInheritance?: Maybe<Scalars['Boolean']['output']>;
  hasChildrenUnder18?: Maybe<Scalars['Boolean']['output']>;
  hasPartnerInheritance?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  personalData: WillPersonalData;
  pets?: Maybe<Array<Pet>>;
  petsFunds?: Maybe<Scalars['Float']['output']>;
  petsGuardians?: Maybe<Array<Person>>;
  residuaryPlan?: Maybe<ResiduaryPlan>;
  shouldAddResiduaryPlanBackups?: Maybe<Scalars['Boolean']['output']>;
  statement?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  wishesAdministrators?: Maybe<Array<Person>>;
};

export type WillFamilyInfo = {
  __typename?: 'WillFamilyInfo';
  lastActiveSubStep: FamilySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillFamilyInfoInput = {
  lastActiveSubStep: FamilySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillFormSteps = {
  __typename?: 'WillFormSteps';
  administrator: WillAdministratorInfo;
  basicInformation: WillBasicInfo;
  family: WillFamilyInfo;
  funeralWishes: WillStepInfo;
  gifts: WillStepInfo;
  id: Scalars['String']['output'];
  residuary: WillResiduaryInfo;
  review: WillReviewInfo;
};

export type WillPersonalData = {
  __typename?: 'WillPersonalData';
  address?: Maybe<WillsAddress>;
  children?: Maybe<Array<Person>>;
  dateOfBirth: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  name: Name;
  partner?: Maybe<Person>;
  personalStatus?: Maybe<PersonalStatues>;
};

export type WillPersonalDataInput = {
  address?: InputMaybe<WillsAddressInput>;
  children?: InputMaybe<Array<PersonInput>>;
  dateOfBirth: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  name: NameInput;
  partner?: InputMaybe<PersonInput>;
  personalStatus?: InputMaybe<PersonalStatues>;
};

export type WillPersonalDataPatchInput = {
  address?: InputMaybe<WillsAddressInput>;
  children?: InputMaybe<Array<PersonInput>>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<NameInput>;
  partner?: InputMaybe<PersonInput>;
  personalStatus?: InputMaybe<PersonalStatues>;
};

export type WillResiduaryInfo = {
  __typename?: 'WillResiduaryInfo';
  lastActiveSubStep: ResiduarySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillResiduaryInfoInput = {
  lastActiveSubStep: ResiduarySubSteps;
  stepStatus: WillStepStatuses;
};

export type WillReviewInfo = {
  __typename?: 'WillReviewInfo';
  lastActiveSubStep: ReviewSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillReviewInfoInput = {
  lastActiveSubStep: ReviewSubSteps;
  stepStatus: WillStepStatuses;
};

export type WillStepInfo = {
  __typename?: 'WillStepInfo';
  lastActiveSubStep?: Maybe<Scalars['String']['output']>;
  stepStatus: WillStepStatuses;
};

export type WillStepInfoInput = {
  lastActiveSubStep?: InputMaybe<Scalars['String']['input']>;
  stepStatus: WillStepStatuses;
};

export enum WillStepStatuses {
  Blocked = 'BLOCKED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type WillsAddress = {
  __typename?: 'WillsAddress';
  city: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  streetDetails?: Maybe<Scalars['String']['output']>;
};

export type WillsAddressInput = {
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetDetails?: InputMaybe<Scalars['String']['input']>;
};

export type SendSupportMsgResponse = {
  __typename?: 'sendSupportMsgResponse';
  isSended: Scalars['Boolean']['output'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  GetPreClientEventsResponse: ( Event ) | ( LssEvent );
};


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Address: ResolverTypeWrapper<Address>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  AddressInput: AddressInput;
  AdministratorSubSteps: AdministratorSubSteps;
  App: App;
  AppointmentPreferences: AppointmentPreferences;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Attendee: ResolverTypeWrapper<Attendee>;
  AttendeeType: AttendeeType;
  AttorneyData: ResolverTypeWrapper<AttorneyData>;
  AttorneyPracticeArea: ResolverTypeWrapper<AttorneyPracticeArea>;
  AttorneyReview: ResolverTypeWrapper<AttorneyReview>;
  AttorneyReviewsFilter: AttorneyReviewsFilter;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  AuthError: AuthError;
  AvailableSlot: ResolverTypeWrapper<AvailableSlot>;
  BackupCharity: ResolverTypeWrapper<BackupCharity>;
  BackupCharityInput: BackupCharityInput;
  BackupPerson: ResolverTypeWrapper<BackupPerson>;
  BackupPersonInput: BackupPersonInput;
  Backups: ResolverTypeWrapper<Backups>;
  BackupsInput: BackupsInput;
  BankOptionsResponse: ResolverTypeWrapper<BankOptionsResponse>;
  BasicInfoSubSteps: BasicInfoSubSteps;
  BeneficiaryCharity: ResolverTypeWrapper<BeneficiaryCharity>;
  BeneficiaryCharityInput: BeneficiaryCharityInput;
  BeneficiaryPerson: ResolverTypeWrapper<BeneficiaryPerson>;
  BeneficiaryPersonInput: BeneficiaryPersonInput;
  BookItMeeting: ResolverTypeWrapper<BookItMeeting>;
  BookItMeetingStatus: BookItMeetingStatus;
  BookeeMeetingType: BookeeMeetingType;
  BookitRequestResponse: ResolverTypeWrapper<BookitRequestResponse>;
  Burial: Burial;
  CalendarMeetingType: CalendarMeetingType;
  CalendlyCanceledEventResponse: ResolverTypeWrapper<CalendlyCanceledEventResponse>;
  CalendlyEventName: CalendlyEventName;
  CalendlyLinksResponse: ResolverTypeWrapper<CalendlyLinksResponse>;
  CancelCalendlyEventRequest: CancelCalendlyEventRequest;
  Case: ResolverTypeWrapper<Omit<Case, 'preClientEvents'> & { preClientEvents?: Maybe<Array<ResolversTypes['GetPreClientEventsResponse']>> }>;
  CaseEvent: ResolverTypeWrapper<CaseEvent>;
  CaseForm: ResolverTypeWrapper<CaseForm>;
  CaseLegalTeam: ResolverTypeWrapper<CaseLegalTeam>;
  CaseStatus: CaseStatus;
  CasesError: CasesError;
  CasesResolverResponse: ResolverTypeWrapper<CasesResolverResponse>;
  CasesStatuses: ResolverTypeWrapper<CasesStatuses>;
  CashGift: ResolverTypeWrapper<CashGift>;
  CashGiftInput: CashGiftInput;
  Ceremony: Ceremony;
  Charity: ResolverTypeWrapper<Charity>;
  CharityInput: CharityInput;
  ClientEmailResponseTime: ClientEmailResponseTime;
  CommunicationMethod: CommunicationMethod;
  CreateBookitRequestPayload: CreateBookitRequestPayload;
  CreateVisitorInput: CreateVisitorInput;
  CreateWillFormStepsRequest: CreateWillFormStepsRequest;
  CreateWillRequest: CreateWillRequest;
  CustomerData: ResolverTypeWrapper<CustomerData>;
  CustomerState: ResolverTypeWrapper<CustomerState>;
  CustomersList: ResolverTypeWrapper<CustomersList>;
  CustomersListFilter: CustomersListFilter;
  DataPointsSubmissionError: DataPointsSubmissionError;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateRange: DateRange;
  DigitalAsset: ResolverTypeWrapper<DigitalAsset>;
  DigitalAssetInput: DigitalAssetInput;
  Document: ResolverTypeWrapper<Document>;
  DocumentDataPoint: DocumentDataPoint;
  DocumentType: ResolverTypeWrapper<DocumentType>;
  DocumentUploadRequest: DocumentUploadRequest;
  DocumentUploadResponse: ResolverTypeWrapper<DocumentUploadResponse>;
  DocumentsDataPointsSubmissionInput: DocumentsDataPointsSubmissionInput;
  DtoGender: DtoGender;
  Education: ResolverTypeWrapper<Education>;
  Event: ResolverTypeWrapper<Event>;
  EventType: EventType;
  FamilySubSteps: FamilySubSteps;
  FeedbackType: FeedbackType;
  FormName: FormName;
  FreezeData: ResolverTypeWrapper<FreezeData>;
  Gender: Gender;
  GenerateWillReponse: ResolverTypeWrapper<GenerateWillReponse>;
  GetAvailableSlotsRequest: GetAvailableSlotsRequest;
  GetBookItMeetingSlotsInput: GetBookItMeetingSlotsInput;
  GetBookItMeetingsInput: GetBookItMeetingsInput;
  GetBookItMeetingsResponse: ResolverTypeWrapper<GetBookItMeetingsResponse>;
  GetCaseUpdateResponse: ResolverTypeWrapper<GetCaseUpdateResponse>;
  GetPreClientEventsResponse: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['GetPreClientEventsResponse']>;
  GetUserCasesInput: GetUserCasesInput;
  Gifts: ResolverTypeWrapper<Gifts>;
  GiftsInput: GiftsInput;
  HashAlgorithm: HashAlgorithm;
  HashError: HashError;
  HashOptions: HashOptions;
  HashRequest: HashRequest;
  HashResponse: ResolverTypeWrapper<HashResponse>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  Language: Language;
  LegalTeamMember: ResolverTypeWrapper<LegalTeamMember>;
  LegalTeamMemberRole: LegalTeamMemberRole;
  Location: ResolverTypeWrapper<Location>;
  LoginByEmailRequest: LoginByEmailRequest;
  LoginByPhoneRequest: LoginByPhoneRequest;
  LoginByTokenRequest: LoginByTokenRequest;
  LoginByTokenResponse: ResolverTypeWrapper<LoginByTokenResponse>;
  LoginError: LoginError;
  LoginImpersonateRequest: LoginImpersonateRequest;
  LoginImpersonateResponse: ResolverTypeWrapper<LoginImpersonateResponse>;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  LssEvent: ResolverTypeWrapper<LssEvent>;
  LssEventData: ResolverTypeWrapper<LssEventData>;
  LssStatus: LssStatus;
  Mutation: ResolverTypeWrapper<{}>;
  Name: ResolverTypeWrapper<Name>;
  NameInput: NameInput;
  OtherBackup: ResolverTypeWrapper<OtherBackup>;
  OtherBackupInput: OtherBackupInput;
  OtherBackupType: OtherBackupType;
  Pagination: ResolverTypeWrapper<Pagination>;
  PaginationInput: PaginationInput;
  PartialName: ResolverTypeWrapper<PartialName>;
  PartialNameInput: PartialNameInput;
  PatchWillRequest: PatchWillRequest;
  Person: ResolverTypeWrapper<Person>;
  PersonInput: PersonInput;
  PersonalStatues: PersonalStatues;
  Pet: ResolverTypeWrapper<Pet>;
  PetInput: PetInput;
  PlatformType: PlatformType;
  PracticeArea: ResolverTypeWrapper<PracticeArea>;
  PracticeAreaLocation: ResolverTypeWrapper<PracticeAreaLocation>;
  PracticeAreaName: PracticeAreaName;
  PredictRequest: PredictRequest;
  PredictResponse: ResolverTypeWrapper<PredictResponse>;
  Property: ResolverTypeWrapper<Property>;
  PropertyInput: PropertyInput;
  Query: ResolverTypeWrapper<{}>;
  RefreshTokenRequest: RefreshTokenRequest;
  RefreshTokenResponse: ResolverTypeWrapper<RefreshTokenResponse>;
  RegisterCaseUpdateFeedbackInput: RegisterCaseUpdateFeedbackInput;
  RegisterDeviceInput: RegisterDeviceInput;
  RejectedModel: ResolverTypeWrapper<RejectedModel>;
  Relation: Relation;
  Repeat: ResolverTypeWrapper<Repeat>;
  RepeatFlowType: RepeatFlowType;
  RepeatService: ResolverTypeWrapper<RepeatService>;
  RepeatStatus: RepeatStatus;
  RepeatSupportRequestError: RepeatSupportRequestError;
  RepeatSupportRequestInput: RepeatSupportRequestInput;
  RepeatSupportRequestTypeEnum: RepeatSupportRequestTypeEnum;
  RequiredDocumentsResponse: ResolverTypeWrapper<RequiredDocumentsResponse>;
  ResiduaryPlan: ResolverTypeWrapper<ResiduaryPlan>;
  ResiduaryPlanCharities: ResolverTypeWrapper<ResiduaryPlanCharities>;
  ResiduaryPlanCharitiesInput: ResiduaryPlanCharitiesInput;
  ResiduaryPlanInput: ResiduaryPlanInput;
  ResiduaryPlanPersons: ResolverTypeWrapper<ResiduaryPlanPersons>;
  ResiduaryPlanPersonsInput: ResiduaryPlanPersonsInput;
  ResiduarySubSteps: ResiduarySubSteps;
  ReviewSubSteps: ReviewSubSteps;
  Role: Role;
  ScheduleLinkRequest: ScheduleLinkRequest;
  ScheduledEventResponse: ResolverTypeWrapper<ScheduledEventResponse>;
  Service: ResolverTypeWrapper<Service>;
  ServiceAttitude: ServiceAttitude;
  ServiceExpertise: ResolverTypeWrapper<ServiceExpertise>;
  ServicePreference: ResolverTypeWrapper<ServicePreference>;
  ServiceStatus: ServiceStatus;
  ServiceType: ResolverTypeWrapper<ServiceType>;
  ServingAttempt: ResolverTypeWrapper<ServingAttempt>;
  ServingStatus: ServingStatus;
  ServingTask: ResolverTypeWrapper<ServingTask>;
  SingleFieldSort: SingleFieldSort;
  SortOrder: SortOrder;
  SourceApps: SourceApps;
  Species: Species;
  SpecificItem: ResolverTypeWrapper<SpecificItem>;
  SpecificItemInput: SpecificItemInput;
  StaticCaseUpdateEnum: StaticCaseUpdateEnum;
  StaticCaseUpdateInput: StaticCaseUpdateInput;
  StepsForClientDto: ResolverTypeWrapper<StepsForClientDto>;
  SubmitDocumentsDataPointsResponse: ResolverTypeWrapper<SubmitDocumentsDataPointsResponse>;
  SubmitRepeatSupportRequestResponse: ResolverTypeWrapper<SubmitRepeatSupportRequestResponse>;
  SubmitUnavailableDocumentError: SubmitUnavailableDocumentError;
  SubmitUnavailableDocumentInput: SubmitUnavailableDocumentInput;
  SubmitUnavailableDocumentResponse: ResolverTypeWrapper<SubmitUnavailableDocumentResponse>;
  SyncInfo: ResolverTypeWrapper<SyncInfo>;
  UpdateDocumentError: UpdateDocumentError;
  UpdateRepeatRequest: UpdateRepeatRequest;
  UpdateResponse: ResolverTypeWrapper<UpdateResponse>;
  UpdateWillFormSteps: ResolverTypeWrapper<UpdateWillFormSteps>;
  UpdateWillFormStepsRequest: UpdateWillFormStepsRequest;
  User: ResolverTypeWrapper<User>;
  UsersError: UsersError;
  UsersResolverResponse: ResolverTypeWrapper<UsersResolverResponse>;
  Vehicle: ResolverTypeWrapper<Vehicle>;
  VehicleInput: VehicleInput;
  VerifyOTPRequest: VerifyOtpRequest;
  VerifyOTPResponse: ResolverTypeWrapper<VerifyOtpResponse>;
  Visitor: ResolverTypeWrapper<Visitor>;
  VisitorStatus: ResolverTypeWrapper<VisitorStatus>;
  VisitorStatusCode: VisitorStatusCode;
  VisitorStatusInputReq: VisitorStatusInputReq;
  WillAdministratorInfo: ResolverTypeWrapper<WillAdministratorInfo>;
  WillAdministratorInfoInput: WillAdministratorInfoInput;
  WillBasicInfo: ResolverTypeWrapper<WillBasicInfo>;
  WillBasicInfoInput: WillBasicInfoInput;
  WillDataPoints: ResolverTypeWrapper<WillDataPoints>;
  WillFamilyInfo: ResolverTypeWrapper<WillFamilyInfo>;
  WillFamilyInfoInput: WillFamilyInfoInput;
  WillFormSteps: ResolverTypeWrapper<WillFormSteps>;
  WillPersonalData: ResolverTypeWrapper<WillPersonalData>;
  WillPersonalDataInput: WillPersonalDataInput;
  WillPersonalDataPatchInput: WillPersonalDataPatchInput;
  WillResiduaryInfo: ResolverTypeWrapper<WillResiduaryInfo>;
  WillResiduaryInfoInput: WillResiduaryInfoInput;
  WillReviewInfo: ResolverTypeWrapper<WillReviewInfo>;
  WillReviewInfoInput: WillReviewInfoInput;
  WillStepInfo: ResolverTypeWrapper<WillStepInfo>;
  WillStepInfoInput: WillStepInfoInput;
  WillStepStatuses: WillStepStatuses;
  WillsAddress: ResolverTypeWrapper<WillsAddress>;
  WillsAddressInput: WillsAddressInput;
  sendSupportMsgResponse: ResolverTypeWrapper<SendSupportMsgResponse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: Address;
  String: Scalars['String']['output'];
  AddressInput: AddressInput;
  AppointmentPreferences: AppointmentPreferences;
  Boolean: Scalars['Boolean']['output'];
  Float: Scalars['Float']['output'];
  Attendee: Attendee;
  AttorneyData: AttorneyData;
  AttorneyPracticeArea: AttorneyPracticeArea;
  AttorneyReview: AttorneyReview;
  AttorneyReviewsFilter: AttorneyReviewsFilter;
  Int: Scalars['Int']['output'];
  AvailableSlot: AvailableSlot;
  BackupCharity: BackupCharity;
  BackupCharityInput: BackupCharityInput;
  BackupPerson: BackupPerson;
  BackupPersonInput: BackupPersonInput;
  Backups: Backups;
  BackupsInput: BackupsInput;
  BankOptionsResponse: BankOptionsResponse;
  BeneficiaryCharity: BeneficiaryCharity;
  BeneficiaryCharityInput: BeneficiaryCharityInput;
  BeneficiaryPerson: BeneficiaryPerson;
  BeneficiaryPersonInput: BeneficiaryPersonInput;
  BookItMeeting: BookItMeeting;
  BookitRequestResponse: BookitRequestResponse;
  CalendlyCanceledEventResponse: CalendlyCanceledEventResponse;
  CalendlyLinksResponse: CalendlyLinksResponse;
  CancelCalendlyEventRequest: CancelCalendlyEventRequest;
  Case: Omit<Case, 'preClientEvents'> & { preClientEvents?: Maybe<Array<ResolversParentTypes['GetPreClientEventsResponse']>> };
  CaseEvent: CaseEvent;
  CaseForm: CaseForm;
  CaseLegalTeam: CaseLegalTeam;
  CasesResolverResponse: CasesResolverResponse;
  CasesStatuses: CasesStatuses;
  CashGift: CashGift;
  CashGiftInput: CashGiftInput;
  Charity: Charity;
  CharityInput: CharityInput;
  CreateBookitRequestPayload: CreateBookitRequestPayload;
  CreateVisitorInput: CreateVisitorInput;
  CreateWillFormStepsRequest: CreateWillFormStepsRequest;
  CreateWillRequest: CreateWillRequest;
  CustomerData: CustomerData;
  CustomerState: CustomerState;
  CustomersList: CustomersList;
  CustomersListFilter: CustomersListFilter;
  Date: Scalars['Date']['output'];
  DateRange: DateRange;
  DigitalAsset: DigitalAsset;
  DigitalAssetInput: DigitalAssetInput;
  Document: Document;
  DocumentDataPoint: DocumentDataPoint;
  DocumentType: DocumentType;
  DocumentUploadRequest: DocumentUploadRequest;
  DocumentUploadResponse: DocumentUploadResponse;
  DocumentsDataPointsSubmissionInput: DocumentsDataPointsSubmissionInput;
  Education: Education;
  Event: Event;
  FreezeData: FreezeData;
  GenerateWillReponse: GenerateWillReponse;
  GetAvailableSlotsRequest: GetAvailableSlotsRequest;
  GetBookItMeetingSlotsInput: GetBookItMeetingSlotsInput;
  GetBookItMeetingsInput: GetBookItMeetingsInput;
  GetBookItMeetingsResponse: GetBookItMeetingsResponse;
  GetCaseUpdateResponse: GetCaseUpdateResponse;
  GetPreClientEventsResponse: ResolversUnionTypes<ResolversParentTypes>['GetPreClientEventsResponse'];
  GetUserCasesInput: GetUserCasesInput;
  Gifts: Gifts;
  GiftsInput: GiftsInput;
  HashOptions: HashOptions;
  HashRequest: HashRequest;
  HashResponse: HashResponse;
  JSON: Scalars['JSON']['output'];
  LegalTeamMember: LegalTeamMember;
  Location: Location;
  LoginByEmailRequest: LoginByEmailRequest;
  LoginByPhoneRequest: LoginByPhoneRequest;
  LoginByTokenRequest: LoginByTokenRequest;
  LoginByTokenResponse: LoginByTokenResponse;
  LoginImpersonateRequest: LoginImpersonateRequest;
  LoginImpersonateResponse: LoginImpersonateResponse;
  LoginResponse: LoginResponse;
  LssEvent: LssEvent;
  LssEventData: LssEventData;
  Mutation: {};
  Name: Name;
  NameInput: NameInput;
  OtherBackup: OtherBackup;
  OtherBackupInput: OtherBackupInput;
  Pagination: Pagination;
  PaginationInput: PaginationInput;
  PartialName: PartialName;
  PartialNameInput: PartialNameInput;
  PatchWillRequest: PatchWillRequest;
  Person: Person;
  PersonInput: PersonInput;
  Pet: Pet;
  PetInput: PetInput;
  PracticeArea: PracticeArea;
  PracticeAreaLocation: PracticeAreaLocation;
  PredictRequest: PredictRequest;
  PredictResponse: PredictResponse;
  Property: Property;
  PropertyInput: PropertyInput;
  Query: {};
  RefreshTokenRequest: RefreshTokenRequest;
  RefreshTokenResponse: RefreshTokenResponse;
  RegisterCaseUpdateFeedbackInput: RegisterCaseUpdateFeedbackInput;
  RegisterDeviceInput: RegisterDeviceInput;
  RejectedModel: RejectedModel;
  Repeat: Repeat;
  RepeatService: RepeatService;
  RepeatSupportRequestInput: RepeatSupportRequestInput;
  RequiredDocumentsResponse: RequiredDocumentsResponse;
  ResiduaryPlan: ResiduaryPlan;
  ResiduaryPlanCharities: ResiduaryPlanCharities;
  ResiduaryPlanCharitiesInput: ResiduaryPlanCharitiesInput;
  ResiduaryPlanInput: ResiduaryPlanInput;
  ResiduaryPlanPersons: ResiduaryPlanPersons;
  ResiduaryPlanPersonsInput: ResiduaryPlanPersonsInput;
  ScheduleLinkRequest: ScheduleLinkRequest;
  ScheduledEventResponse: ScheduledEventResponse;
  Service: Service;
  ServiceExpertise: ServiceExpertise;
  ServicePreference: ServicePreference;
  ServiceType: ServiceType;
  ServingAttempt: ServingAttempt;
  ServingTask: ServingTask;
  SingleFieldSort: SingleFieldSort;
  SpecificItem: SpecificItem;
  SpecificItemInput: SpecificItemInput;
  StaticCaseUpdateInput: StaticCaseUpdateInput;
  StepsForClientDto: StepsForClientDto;
  SubmitDocumentsDataPointsResponse: SubmitDocumentsDataPointsResponse;
  SubmitRepeatSupportRequestResponse: SubmitRepeatSupportRequestResponse;
  SubmitUnavailableDocumentInput: SubmitUnavailableDocumentInput;
  SubmitUnavailableDocumentResponse: SubmitUnavailableDocumentResponse;
  SyncInfo: SyncInfo;
  UpdateRepeatRequest: UpdateRepeatRequest;
  UpdateResponse: UpdateResponse;
  UpdateWillFormSteps: UpdateWillFormSteps;
  UpdateWillFormStepsRequest: UpdateWillFormStepsRequest;
  User: User;
  UsersResolverResponse: UsersResolverResponse;
  Vehicle: Vehicle;
  VehicleInput: VehicleInput;
  VerifyOTPRequest: VerifyOtpRequest;
  VerifyOTPResponse: VerifyOtpResponse;
  Visitor: Visitor;
  VisitorStatus: VisitorStatus;
  VisitorStatusInputReq: VisitorStatusInputReq;
  WillAdministratorInfo: WillAdministratorInfo;
  WillAdministratorInfoInput: WillAdministratorInfoInput;
  WillBasicInfo: WillBasicInfo;
  WillBasicInfoInput: WillBasicInfoInput;
  WillDataPoints: WillDataPoints;
  WillFamilyInfo: WillFamilyInfo;
  WillFamilyInfoInput: WillFamilyInfoInput;
  WillFormSteps: WillFormSteps;
  WillPersonalData: WillPersonalData;
  WillPersonalDataInput: WillPersonalDataInput;
  WillPersonalDataPatchInput: WillPersonalDataPatchInput;
  WillResiduaryInfo: WillResiduaryInfo;
  WillResiduaryInfoInput: WillResiduaryInfoInput;
  WillReviewInfo: WillReviewInfo;
  WillReviewInfoInput: WillReviewInfoInput;
  WillStepInfo: WillStepInfo;
  WillStepInfoInput: WillStepInfoInput;
  WillsAddress: WillsAddress;
  WillsAddressInput: WillsAddressInput;
  sendSupportMsgResponse: SendSupportMsgResponse;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attendee'] = ResolversParentTypes['Attendee']> = {
  attendeeType?: Resolver<ResolversTypes['AttendeeType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttorneyDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttorneyData'] = ResolversParentTypes['AttorneyData']> = {
  clientEmailResponsiveness?: Resolver<Maybe<ResolversTypes['ClientEmailResponseTime']>, ParentType, ContextType>;
  education?: Resolver<Maybe<Array<ResolversTypes['Education']>>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<ResolversTypes['Language']>>, ParentType, ContextType>;
  practiceAreas?: Resolver<Maybe<Array<ResolversTypes['AttorneyPracticeArea']>>, ParentType, ContextType>;
  practiceAreasIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  preferredCommunicationMethods?: Resolver<Maybe<Array<ResolversTypes['CommunicationMethod']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttorneyPracticeAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttorneyPracticeArea'] = ResolversParentTypes['AttorneyPracticeArea']> = {
  handlesCases?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['PracticeAreaLocation']>, ParentType, ContextType>;
  performsLSS?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  practiceArea?: Resolver<ResolversTypes['PracticeArea'], ParentType, ContextType>;
  practiceAreaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceExpertises?: Resolver<Array<ResolversTypes['ServiceExpertise']>, ParentType, ContextType>;
  servicePreferences?: Resolver<Array<ResolversTypes['ServicePreference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttorneyReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttorneyReview'] = ResolversParentTypes['AttorneyReview']> = {
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reviewDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableSlotResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvailableSlot'] = ResolversParentTypes['AvailableSlot']> = {
  end?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BackupCharityResolvers<ContextType = any, ParentType extends ResolversParentTypes['BackupCharity'] = ResolversParentTypes['BackupCharity']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BackupPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['BackupPerson'] = ResolversParentTypes['BackupPerson']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  relation?: Resolver<ResolversTypes['Relation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BackupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Backups'] = ResolversParentTypes['Backups']> = {
  backupCharity?: Resolver<Maybe<Array<ResolversTypes['BackupCharity']>>, ParentType, ContextType>;
  backupPerson?: Resolver<Maybe<Array<ResolversTypes['BackupPerson']>>, ParentType, ContextType>;
  otherBackup?: Resolver<Maybe<ResolversTypes['OtherBackup']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankOptionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankOptionsResponse'] = ResolversParentTypes['BankOptionsResponse']> = {
  persons?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BeneficiaryCharityResolvers<ContextType = any, ParentType extends ResolversParentTypes['BeneficiaryCharity'] = ResolversParentTypes['BeneficiaryCharity']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BeneficiaryPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['BeneficiaryPerson'] = ResolversParentTypes['BeneficiaryPerson']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  relation?: Resolver<ResolversTypes['Relation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookItMeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookItMeeting'] = ResolversParentTypes['BookItMeeting']> = {
  clientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meetingType?: Resolver<Maybe<ResolversTypes['CalendarMeetingType']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BookItMeetingStatus'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookitRequestResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookitRequestResponse'] = ResolversParentTypes['BookitRequestResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendlyCanceledEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalendlyCanceledEventResponse'] = ResolversParentTypes['CalendlyCanceledEventResponse']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CalendlyLinksResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalendlyLinksResponse'] = ResolversParentTypes['CalendlyLinksResponse']> = {
  cancelUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isRescheduleDatesAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rescheduleUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Case'] = ResolversParentTypes['Case']> = {
  additionalFields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  caseStartDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  caseUpdate?: Resolver<Maybe<ResolversTypes['GetCaseUpdateResponse']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  events?: Resolver<Maybe<Array<ResolversTypes['CaseEvent']>>, ParentType, ContextType>;
  forms?: Resolver<Maybe<Array<ResolversTypes['CaseForm']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  legalTeam?: Resolver<Maybe<ResolversTypes['CaseLegalTeam']>, ParentType, ContextType>;
  opposingParty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practiceArea?: Resolver<ResolversTypes['PracticeArea'], ParentType, ContextType>;
  practiceAreaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preClientEvents?: Resolver<Maybe<Array<ResolversTypes['GetPreClientEventsResponse']>>, ParentType, ContextType>;
  repeats?: Resolver<Maybe<Array<ResolversTypes['Repeat']>>, ParentType, ContextType>;
  services?: Resolver<Maybe<Array<ResolversTypes['Service']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseStatus'], ParentType, ContextType>;
  strategyReviewCallComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  strategyReviewCallCompleteDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseEvent'] = ResolversParentTypes['CaseEvent']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseFormResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseForm'] = ResolversParentTypes['CaseForm']> = {
  name?: Resolver<ResolversTypes['FormName'], ParentType, ContextType>;
  saved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  submitted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseLegalTeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseLegalTeam'] = ResolversParentTypes['CaseLegalTeam']> = {
  caseManager?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  paralegal?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  responsibleAttorney?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasesResolverResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasesResolverResponse'] = ResolversParentTypes['CasesResolverResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['CasesError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasesStatusesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasesStatuses'] = ResolversParentTypes['CasesStatuses']> = {
  accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rejected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['CashGift'] = ResolversParentTypes['CashGift']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  beneficiaryCharity?: Resolver<Maybe<ResolversTypes['BeneficiaryCharity']>, ParentType, ContextType>;
  beneficiaryPerson?: Resolver<Maybe<ResolversTypes['BeneficiaryPerson']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CharityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Charity'] = ResolversParentTypes['Charity']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerData'] = ResolversParentTypes['CustomerData']> = {
  consentForCreditCheck?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  customerState?: Resolver<Maybe<ResolversTypes['CustomerState']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerState'] = ResolversParentTypes['CustomerState']> = {
  blockAllCommunications?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  emailConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstEmailSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  freezeData?: Resolver<Maybe<ResolversTypes['FreezeData']>, ParentType, ContextType>;
  postBookingShown?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  repeatFlowType?: Resolver<Maybe<ResolversTypes['RepeatFlowType']>, ParentType, ContextType>;
  submittedForms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visitedMyMarbleWeb?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  visitedOnboardingWizard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomersListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomersList'] = ResolversParentTypes['CustomersList']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DigitalAssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['DigitalAsset'] = ResolversParentTypes['DigitalAsset']> = {
  beneficiaryCharity?: Resolver<Maybe<ResolversTypes['BeneficiaryCharity']>, ParentType, ContextType>;
  beneficiaryPerson?: Resolver<Maybe<ResolversTypes['BeneficiaryPerson']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerMarbleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerVisibility?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  documentType?: Resolver<ResolversTypes['DocumentType'], ParentType, ContextType>;
  documentTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  s3UploadDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  sourceApp?: Resolver<ResolversTypes['SourceApps'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentType'] = ResolversParentTypes['DocumentType']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  howToObtain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  importanceDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentUploadResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentUploadResponse'] = ResolversParentTypes['DocumentUploadResponse']> = {
  document?: Resolver<ResolversTypes['Document'], ParentType, ContextType>;
  documentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EducationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Education'] = ResolversParentTypes['Education']> = {
  graduationYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  schoolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  attendees?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType>;
  bookeeEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookeeMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendlyEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  syncInfo?: Resolver<Maybe<ResolversTypes['SyncInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FreezeDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['FreezeData'] = ResolversParentTypes['FreezeData']> = {
  frozenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  initiatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isFrozen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateWillReponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenerateWillReponse'] = ResolversParentTypes['GenerateWillReponse']> = {
  downloadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetBookItMeetingsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetBookItMeetingsResponse'] = ResolversParentTypes['GetBookItMeetingsResponse']> = {
  meetings?: Resolver<Array<ResolversTypes['BookItMeeting']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pageSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCaseUpdateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCaseUpdateResponse'] = ResolversParentTypes['GetCaseUpdateResponse']> = {
  caseUpdate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseUpdateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerMarbleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedbackCreatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedbackType?: Resolver<Maybe<ResolversTypes['FeedbackType']>, ParentType, ContextType>;
  negativeFeedbackFreeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  negativeFeedbackReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPreClientEventsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetPreClientEventsResponse'] = ResolversParentTypes['GetPreClientEventsResponse']> = {
  __resolveType: TypeResolveFn<'Event' | 'LssEvent', ParentType, ContextType>;
};

export type GiftsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gifts'] = ResolversParentTypes['Gifts']> = {
  cashGifts?: Resolver<Maybe<Array<ResolversTypes['CashGift']>>, ParentType, ContextType>;
  digitalAssets?: Resolver<Maybe<Array<ResolversTypes['DigitalAsset']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<ResolversTypes['Property']>>, ParentType, ContextType>;
  specificItems?: Resolver<Maybe<Array<ResolversTypes['SpecificItem']>>, ParentType, ContextType>;
  vehicles?: Resolver<Maybe<Array<ResolversTypes['Vehicle']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HashResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['HashResponse'] = ResolversParentTypes['HashResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['HashError']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LegalTeamMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegalTeamMember'] = ResolversParentTypes['LegalTeamMember']> = {
  role?: Resolver<ResolversTypes['LegalTeamMemberRole'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginByTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginByTokenResponse'] = ResolversParentTypes['LoginByTokenResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['LoginError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginImpersonateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginImpersonateResponse'] = ResolversParentTypes['LoginImpersonateResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['LoginError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssEvent'] = ResolversParentTypes['LssEvent']> = {
  attendees?: Resolver<Array<ResolversTypes['Attendee']>, ParentType, ContextType>;
  bookeeEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookeeMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendlyEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['LssEventData'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  syncInfo?: Resolver<Maybe<ResolversTypes['SyncInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LssEventDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['LssEventData'] = ResolversParentTypes['LssEventData']> = {
  lssStatus?: Resolver<ResolversTypes['LssStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  cancelCalendlyScheduledEvent?: Resolver<ResolversTypes['CalendlyCanceledEventResponse'], ParentType, ContextType, RequireFields<MutationCancelCalendlyScheduledEventArgs, 'payload'>>;
  createBookitRequest?: Resolver<ResolversTypes['BookitRequestResponse'], ParentType, ContextType, RequireFields<MutationCreateBookitRequestArgs, 'payload'>>;
  createVisitor?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType, RequireFields<MutationCreateVisitorArgs, 'newVisitor'>>;
  createWillDataPoints?: Resolver<ResolversTypes['WillDataPoints'], ParentType, ContextType, RequireFields<MutationCreateWillDataPointsArgs, 'payload'>>;
  createWillFormSteps?: Resolver<Maybe<ResolversTypes['WillFormSteps']>, ParentType, ContextType, RequireFields<MutationCreateWillFormStepsArgs, 'payload'>>;
  flagSubmittedQuestionnaire?: Resolver<ResolversTypes['CasesResolverResponse'], ParentType, ContextType, RequireFields<MutationFlagSubmittedQuestionnaireArgs, 'caseId'>>;
  flagVisitedMyMarbleWeb?: Resolver<ResolversTypes['UsersResolverResponse'], ParentType, ContextType>;
  flagVisitedOnboardingWizard?: Resolver<ResolversTypes['UsersResolverResponse'], ParentType, ContextType>;
  generateSingleUseSchedulingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationGenerateSingleUseSchedulingUrlArgs, 'payload'>>;
  generateWillPdf?: Resolver<Maybe<ResolversTypes['GenerateWillReponse']>, ParentType, ContextType, RequireFields<MutationGenerateWillPdfArgs, 'willId'>>;
  hash?: Resolver<ResolversTypes['HashResponse'], ParentType, ContextType, RequireFields<MutationHashArgs, 'request'>>;
  loginByEmail?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<MutationLoginByEmailArgs, 'loginByEmailRequest'>>;
  loginByPhone?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<MutationLoginByPhoneArgs, 'loginByPhoneRequest'>>;
  loginByToken?: Resolver<ResolversTypes['LoginByTokenResponse'], ParentType, ContextType, RequireFields<MutationLoginByTokenArgs, 'loginByTokenRequest'>>;
  loginImpersonate?: Resolver<ResolversTypes['LoginImpersonateResponse'], ParentType, ContextType, RequireFields<MutationLoginImpersonateArgs, 'loginImpersonateRequest'>>;
  patchWillDataPoints?: Resolver<Maybe<ResolversTypes['WillDataPoints']>, ParentType, ContextType, RequireFields<MutationPatchWillDataPointsArgs, 'payload'>>;
  predict?: Resolver<ResolversTypes['PredictResponse'], ParentType, ContextType, RequireFields<MutationPredictArgs, 'payload'>>;
  refreshToken?: Resolver<ResolversTypes['RefreshTokenResponse'], ParentType, ContextType, RequireFields<MutationRefreshTokenArgs, 'refreshTokenRequest'>>;
  registerCaseUpdateFeedback?: Resolver<ResolversTypes['CasesResolverResponse'], ParentType, ContextType, RequireFields<MutationRegisterCaseUpdateFeedbackArgs, 'input'>>;
  registerDevice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRegisterDeviceArgs, 'input'>>;
  registerUserSeenUpdate?: Resolver<ResolversTypes['CasesResolverResponse'], ParentType, ContextType, RequireFields<MutationRegisterUserSeenUpdateArgs, 'caseUpdateId'>>;
  removeDocumentCustomerVisibility?: Resolver<ResolversTypes['UpdateResponse'], ParentType, ContextType, RequireFields<MutationRemoveDocumentCustomerVisibilityArgs, 'id'>>;
  renameDocument?: Resolver<ResolversTypes['UpdateResponse'], ParentType, ContextType, RequireFields<MutationRenameDocumentArgs, 'id' | 'newFileName'>>;
  requestDocumentUpload?: Resolver<ResolversTypes['DocumentUploadResponse'], ParentType, ContextType, RequireFields<MutationRequestDocumentUploadArgs, 'payload'>>;
  sendSupportMsg?: Resolver<Maybe<ResolversTypes['sendSupportMsgResponse']>, ParentType, ContextType, RequireFields<MutationSendSupportMsgArgs, 'msg'>>;
  submitDocumentsDataPoints?: Resolver<ResolversTypes['SubmitDocumentsDataPointsResponse'], ParentType, ContextType, RequireFields<MutationSubmitDocumentsDataPointsArgs, 'input'>>;
  submitRepeatSupportRequest?: Resolver<ResolversTypes['SubmitRepeatSupportRequestResponse'], ParentType, ContextType, RequireFields<MutationSubmitRepeatSupportRequestArgs, 'input'>>;
  submitUnavailableDocument?: Resolver<ResolversTypes['SubmitUnavailableDocumentResponse'], ParentType, ContextType, RequireFields<MutationSubmitUnavailableDocumentArgs, 'input'>>;
  updateRepeat?: Resolver<ResolversTypes['Repeat'], ParentType, ContextType, RequireFields<MutationUpdateRepeatArgs, 'payload' | 'repeatId'>>;
  updateWillFormSteps?: Resolver<Maybe<ResolversTypes['UpdateWillFormSteps']>, ParentType, ContextType, RequireFields<MutationUpdateWillFormStepsArgs, 'payload'>>;
  verifyOTP?: Resolver<ResolversTypes['VerifyOTPResponse'], ParentType, ContextType, RequireFields<MutationVerifyOtpArgs, 'verifyOTPRequest'>>;
};

export type NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['Name'] = ResolversParentTypes['Name']> = {
  first?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OtherBackupResolvers<ContextType = any, ParentType extends ResolversParentTypes['OtherBackup'] = ResolversParentTypes['OtherBackup']> = {
  optionType?: Resolver<ResolversTypes['OtherBackupType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']> = {
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartialNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialName'] = ResolversParentTypes['PartialName']> = {
  first?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  relation?: Resolver<ResolversTypes['Relation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pet'] = ResolversParentTypes['Pet']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  species?: Resolver<ResolversTypes['Species'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PracticeAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['PracticeArea'] = ResolversParentTypes['PracticeArea']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PracticeAreaLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PracticeAreaLocation'] = ResolversParentTypes['PracticeAreaLocation']> = {
  fips?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PredictResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PredictResponse'] = ResolversParentTypes['PredictResponse']> = {
  probability?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Property'] = ResolversParentTypes['Property']> = {
  address?: Resolver<ResolversTypes['WillsAddress'], ParentType, ContextType>;
  beneficiaryCharity?: Resolver<Maybe<ResolversTypes['BeneficiaryCharity']>, ParentType, ContextType>;
  beneficiaryPerson?: Resolver<Maybe<ResolversTypes['BeneficiaryPerson']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getAllPersonValuesByWillId?: Resolver<Maybe<ResolversTypes['BankOptionsResponse']>, ParentType, ContextType, RequireFields<QueryGetAllPersonValuesByWillIdArgs, 'willId'>>;
  getAttorneyReviews?: Resolver<Maybe<Array<ResolversTypes['AttorneyReview']>>, ParentType, ContextType, RequireFields<QueryGetAttorneyReviewsArgs, 'filter'>>;
  getAuthenticatedUser?: Resolver<ResolversTypes['UsersResolverResponse'], ParentType, ContextType>;
  getAvailableSlots?: Resolver<Array<ResolversTypes['AvailableSlot']>, ParentType, ContextType, RequireFields<QueryGetAvailableSlotsArgs, 'payload'>>;
  getBookItMeetingSlots?: Resolver<Array<ResolversTypes['AvailableSlot']>, ParentType, ContextType, RequireFields<QueryGetBookItMeetingSlotsArgs, 'input'>>;
  getBookItMeetings?: Resolver<ResolversTypes['GetBookItMeetingsResponse'], ParentType, ContextType, RequireFields<QueryGetBookItMeetingsArgs, 'getBookItMeetingsInput'>>;
  getCalendlyLinks?: Resolver<ResolversTypes['CalendlyLinksResponse'], ParentType, ContextType, RequireFields<QueryGetCalendlyLinksArgs, 'eventId'>>;
  getCaseById?: Resolver<Maybe<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<QueryGetCaseByIdArgs, 'id'>>;
  getCasesByUserId?: Resolver<Maybe<Array<ResolversTypes['Case']>>, ParentType, ContextType, RequireFields<QueryGetCasesByUserIdArgs, 'id'>>;
  getCustomer?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryGetCustomerArgs, 'id'>>;
  getCustomerCases?: Resolver<Maybe<Array<ResolversTypes['Case']>>, ParentType, ContextType>;
  getCustomerDocuments?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType>;
  getDocumentDownloadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetDocumentDownloadUrlArgs, 'documentId'>>;
  getDocumentsByCaseId?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<QueryGetDocumentsByCaseIdArgs, 'caseId'>>;
  getRequiredDocuments?: Resolver<ResolversTypes['RequiredDocumentsResponse'], ParentType, ContextType>;
  getScheduledEventData?: Resolver<ResolversTypes['ScheduledEventResponse'], ParentType, ContextType, RequireFields<QueryGetScheduledEventDataArgs, 'scheduledEventUri'>>;
  getServingTaskByCaseId?: Resolver<Maybe<Array<ResolversTypes['ServingTask']>>, ParentType, ContextType, RequireFields<QueryGetServingTaskByCaseIdArgs, 'caseId'>>;
  getStaticCaseUpdate?: Resolver<Maybe<ResolversTypes['GetCaseUpdateResponse']>, ParentType, ContextType, RequireFields<QueryGetStaticCaseUpdateArgs, 'input'>>;
  getSupportedUploadDocumentTypes?: Resolver<Array<ResolversTypes['DocumentType']>, ParentType, ContextType>;
  getUserCasesByStatus?: Resolver<Maybe<Array<ResolversTypes['Case']>>, ParentType, ContextType, Partial<QueryGetUserCasesByStatusArgs>>;
  getVisitorById?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType, RequireFields<QueryGetVisitorByIdArgs, 'visitorId'>>;
  getVisitorStatus?: Resolver<ResolversTypes['VisitorStatus'], ParentType, ContextType, RequireFields<QueryGetVisitorStatusArgs, 'visitorStatusInput'>>;
  getWillDataById?: Resolver<Maybe<ResolversTypes['WillDataPoints']>, ParentType, ContextType, RequireFields<QueryGetWillDataByIdArgs, 'willId'>>;
  getWillFormStepsByUser?: Resolver<Maybe<Array<ResolversTypes['WillFormSteps']>>, ParentType, ContextType>;
  getWillsDataByUserId?: Resolver<Array<ResolversTypes['WillDataPoints']>, ParentType, ContextType>;
  hasAvailableSlots?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryHasAvailableSlotsArgs, 'payload'>>;
  listCustomers?: Resolver<ResolversTypes['CustomersList'], ParentType, ContextType, Partial<QueryListCustomersArgs>>;
};

export type RefreshTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefreshTokenResponse'] = ResolversParentTypes['RefreshTokenResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['AuthError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RejectedModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['RejectedModel'] = ResolversParentTypes['RejectedModel']> = {
  rejectedNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rejectedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepeatResolvers<ContextType = any, ParentType extends ResolversParentTypes['Repeat'] = ResolversParentTypes['Repeat']> = {
  attorneyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attorneyNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  changedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isNoTouchFlowEligible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rejected?: Resolver<Maybe<ResolversTypes['RejectedModel']>, ParentType, ContextType>;
  services?: Resolver<ResolversTypes['RepeatService'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['RepeatStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepeatServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['RepeatService'] = ResolversParentTypes['RepeatService']> = {
  addedServiceIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  removedServicesIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequiredDocumentsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequiredDocumentsResponse'] = ResolversParentTypes['RequiredDocumentsResponse']> = {
  documentTypes?: Resolver<Array<ResolversTypes['DocumentType']>, ParentType, ContextType>;
  lssIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  repeatIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResiduaryPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResiduaryPlan'] = ResolversParentTypes['ResiduaryPlan']> = {
  charities?: Resolver<Maybe<Array<ResolversTypes['ResiduaryPlanCharities']>>, ParentType, ContextType>;
  persons?: Resolver<Maybe<Array<ResolversTypes['ResiduaryPlanPersons']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResiduaryPlanCharitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResiduaryPlanCharities'] = ResolversParentTypes['ResiduaryPlanCharities']> = {
  backups?: Resolver<Maybe<ResolversTypes['Backups']>, ParentType, ContextType>;
  charity?: Resolver<ResolversTypes['Charity'], ParentType, ContextType>;
  percent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResiduaryPlanPersonsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResiduaryPlanPersons'] = ResolversParentTypes['ResiduaryPlanPersons']> = {
  backups?: Resolver<Maybe<ResolversTypes['Backups']>, ParentType, ContextType>;
  percent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  person?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledEventResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledEventResponse'] = ResolversParentTypes['ScheduledEventResponse']> = {
  endTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Service'] = ResolversParentTypes['Service']> = {
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  legalTeam?: Resolver<Array<ResolversTypes['LegalTeamMember']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  practiceAreaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceType?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>;
  serviceTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ServiceStatus'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceExpertiseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceExpertise'] = ResolversParentTypes['ServiceExpertise']> = {
  attitude?: Resolver<Maybe<ResolversTypes['ServiceAttitude']>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePreferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePreference'] = ResolversParentTypes['ServicePreference']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceType'] = ResolversParentTypes['ServiceType']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  descriptionForClient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  practiceAreaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stepsForClient?: Resolver<Maybe<Array<ResolversTypes['StepsForClientDto']>>, ParentType, ContextType>;
  suggestedNextSteps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whatHappensNext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whatIsIncluded?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  whatIsNotIncluded?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServingAttemptResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServingAttempt'] = ResolversParentTypes['ServingAttempt']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attemptDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  attemptId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServingTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServingTask'] = ResolversParentTypes['ServingTask']> = {
  attempts?: Resolver<Array<ResolversTypes['ServingAttempt']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ServingStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecificItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecificItem'] = ResolversParentTypes['SpecificItem']> = {
  beneficiaryCharity?: Resolver<Maybe<ResolversTypes['BeneficiaryCharity']>, ParentType, ContextType>;
  beneficiaryPerson?: Resolver<Maybe<ResolversTypes['BeneficiaryPerson']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StepsForClientDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepsForClientDto'] = ResolversParentTypes['StepsForClientDto']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stepNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitDocumentsDataPointsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmitDocumentsDataPointsResponse'] = ResolversParentTypes['SubmitDocumentsDataPointsResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['DataPointsSubmissionError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitRepeatSupportRequestResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmitRepeatSupportRequestResponse'] = ResolversParentTypes['SubmitRepeatSupportRequestResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['RepeatSupportRequestError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitUnavailableDocumentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmitUnavailableDocumentResponse'] = ResolversParentTypes['SubmitUnavailableDocumentResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['SubmitUnavailableDocumentError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SyncInfo'] = ResolversParentTypes['SyncInfo']> = {
  salesforceOpportunityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateResponse'] = ResolversParentTypes['UpdateResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['UpdateDocumentError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateWillFormStepsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateWillFormSteps'] = ResolversParentTypes['UpdateWillFormSteps']> = {
  administrator?: Resolver<Maybe<ResolversTypes['WillAdministratorInfo']>, ParentType, ContextType>;
  basicInformation?: Resolver<Maybe<ResolversTypes['WillBasicInfo']>, ParentType, ContextType>;
  family?: Resolver<Maybe<ResolversTypes['WillFamilyInfo']>, ParentType, ContextType>;
  funeralWishes?: Resolver<Maybe<ResolversTypes['WillStepInfo']>, ParentType, ContextType>;
  gifts?: Resolver<Maybe<ResolversTypes['WillStepInfo']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  residuary?: Resolver<Maybe<ResolversTypes['WillResiduaryInfo']>, ParentType, ContextType>;
  review?: Resolver<Maybe<ResolversTypes['WillReviewInfo']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  attorneyData?: Resolver<Maybe<ResolversTypes['AttorneyData']>, ParentType, ContextType>;
  bookItId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  casesStatuses?: Resolver<ResolversTypes['CasesStatuses'], ParentType, ContextType>;
  customerData?: Resolver<Maybe<ResolversTypes['CustomerData']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailAlias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasCase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  marbleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolverResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersResolverResponse'] = ResolversParentTypes['UsersResolverResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['UsersError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vehicle'] = ResolversParentTypes['Vehicle']> = {
  beneficiaryCharity?: Resolver<Maybe<ResolversTypes['BeneficiaryCharity']>, ParentType, ContextType>;
  beneficiaryPerson?: Resolver<Maybe<ResolversTypes['BeneficiaryPerson']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyOtpResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyOTPResponse'] = ResolversParentTypes['VerifyOTPResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['LoginError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Visitor'] = ResolversParentTypes['Visitor']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  anonymousId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  funnelAnswers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leadScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['PartialName']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utmData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorStatus'] = ResolversParentTypes['VisitorStatus']> = {
  statusCode?: Resolver<ResolversTypes['VisitorStatusCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillAdministratorInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillAdministratorInfo'] = ResolversParentTypes['WillAdministratorInfo']> = {
  lastActiveSubStep?: Resolver<ResolversTypes['AdministratorSubSteps'], ParentType, ContextType>;
  stepStatus?: Resolver<ResolversTypes['WillStepStatuses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillBasicInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillBasicInfo'] = ResolversParentTypes['WillBasicInfo']> = {
  lastActiveSubStep?: Resolver<ResolversTypes['BasicInfoSubSteps'], ParentType, ContextType>;
  stepStatus?: Resolver<ResolversTypes['WillStepStatuses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillDataPointsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillDataPoints'] = ResolversParentTypes['WillDataPoints']> = {
  administrators?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  burial?: Resolver<Maybe<ResolversTypes['Burial']>, ParentType, ContextType>;
  burialAdditionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ceremony?: Resolver<Maybe<ResolversTypes['Ceremony']>, ParentType, ContextType>;
  ceremonyAdditionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  childrenGuardians?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  gifts?: Resolver<Maybe<ResolversTypes['Gifts']>, ParentType, ContextType>;
  hasChildrenInheritance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasChildrenUnder18?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPartnerInheritance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  personalData?: Resolver<ResolversTypes['WillPersonalData'], ParentType, ContextType>;
  pets?: Resolver<Maybe<Array<ResolversTypes['Pet']>>, ParentType, ContextType>;
  petsFunds?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  petsGuardians?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  residuaryPlan?: Resolver<Maybe<ResolversTypes['ResiduaryPlan']>, ParentType, ContextType>;
  shouldAddResiduaryPlanBackups?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  statement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wishesAdministrators?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillFamilyInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillFamilyInfo'] = ResolversParentTypes['WillFamilyInfo']> = {
  lastActiveSubStep?: Resolver<ResolversTypes['FamilySubSteps'], ParentType, ContextType>;
  stepStatus?: Resolver<ResolversTypes['WillStepStatuses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillFormStepsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillFormSteps'] = ResolversParentTypes['WillFormSteps']> = {
  administrator?: Resolver<ResolversTypes['WillAdministratorInfo'], ParentType, ContextType>;
  basicInformation?: Resolver<ResolversTypes['WillBasicInfo'], ParentType, ContextType>;
  family?: Resolver<ResolversTypes['WillFamilyInfo'], ParentType, ContextType>;
  funeralWishes?: Resolver<ResolversTypes['WillStepInfo'], ParentType, ContextType>;
  gifts?: Resolver<ResolversTypes['WillStepInfo'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  residuary?: Resolver<ResolversTypes['WillResiduaryInfo'], ParentType, ContextType>;
  review?: Resolver<ResolversTypes['WillReviewInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillPersonalDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillPersonalData'] = ResolversParentTypes['WillPersonalData']> = {
  address?: Resolver<Maybe<ResolversTypes['WillsAddress']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<ResolversTypes['Person']>>, ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Name'], ParentType, ContextType>;
  partner?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  personalStatus?: Resolver<Maybe<ResolversTypes['PersonalStatues']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillResiduaryInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillResiduaryInfo'] = ResolversParentTypes['WillResiduaryInfo']> = {
  lastActiveSubStep?: Resolver<ResolversTypes['ResiduarySubSteps'], ParentType, ContextType>;
  stepStatus?: Resolver<ResolversTypes['WillStepStatuses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillReviewInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillReviewInfo'] = ResolversParentTypes['WillReviewInfo']> = {
  lastActiveSubStep?: Resolver<ResolversTypes['ReviewSubSteps'], ParentType, ContextType>;
  stepStatus?: Resolver<ResolversTypes['WillStepStatuses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillStepInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillStepInfo'] = ResolversParentTypes['WillStepInfo']> = {
  lastActiveSubStep?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stepStatus?: Resolver<ResolversTypes['WillStepStatuses'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillsAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillsAddress'] = ResolversParentTypes['WillsAddress']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendSupportMsgResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['sendSupportMsgResponse'] = ResolversParentTypes['sendSupportMsgResponse']> = {
  isSended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Address?: AddressResolvers<ContextType>;
  Attendee?: AttendeeResolvers<ContextType>;
  AttorneyData?: AttorneyDataResolvers<ContextType>;
  AttorneyPracticeArea?: AttorneyPracticeAreaResolvers<ContextType>;
  AttorneyReview?: AttorneyReviewResolvers<ContextType>;
  AvailableSlot?: AvailableSlotResolvers<ContextType>;
  BackupCharity?: BackupCharityResolvers<ContextType>;
  BackupPerson?: BackupPersonResolvers<ContextType>;
  Backups?: BackupsResolvers<ContextType>;
  BankOptionsResponse?: BankOptionsResponseResolvers<ContextType>;
  BeneficiaryCharity?: BeneficiaryCharityResolvers<ContextType>;
  BeneficiaryPerson?: BeneficiaryPersonResolvers<ContextType>;
  BookItMeeting?: BookItMeetingResolvers<ContextType>;
  BookitRequestResponse?: BookitRequestResponseResolvers<ContextType>;
  CalendlyCanceledEventResponse?: CalendlyCanceledEventResponseResolvers<ContextType>;
  CalendlyLinksResponse?: CalendlyLinksResponseResolvers<ContextType>;
  Case?: CaseResolvers<ContextType>;
  CaseEvent?: CaseEventResolvers<ContextType>;
  CaseForm?: CaseFormResolvers<ContextType>;
  CaseLegalTeam?: CaseLegalTeamResolvers<ContextType>;
  CasesResolverResponse?: CasesResolverResponseResolvers<ContextType>;
  CasesStatuses?: CasesStatusesResolvers<ContextType>;
  CashGift?: CashGiftResolvers<ContextType>;
  Charity?: CharityResolvers<ContextType>;
  CustomerData?: CustomerDataResolvers<ContextType>;
  CustomerState?: CustomerStateResolvers<ContextType>;
  CustomersList?: CustomersListResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DigitalAsset?: DigitalAssetResolvers<ContextType>;
  Document?: DocumentResolvers<ContextType>;
  DocumentType?: DocumentTypeResolvers<ContextType>;
  DocumentUploadResponse?: DocumentUploadResponseResolvers<ContextType>;
  Education?: EducationResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  FreezeData?: FreezeDataResolvers<ContextType>;
  GenerateWillReponse?: GenerateWillReponseResolvers<ContextType>;
  GetBookItMeetingsResponse?: GetBookItMeetingsResponseResolvers<ContextType>;
  GetCaseUpdateResponse?: GetCaseUpdateResponseResolvers<ContextType>;
  GetPreClientEventsResponse?: GetPreClientEventsResponseResolvers<ContextType>;
  Gifts?: GiftsResolvers<ContextType>;
  HashResponse?: HashResponseResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LegalTeamMember?: LegalTeamMemberResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LoginByTokenResponse?: LoginByTokenResponseResolvers<ContextType>;
  LoginImpersonateResponse?: LoginImpersonateResponseResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  LssEvent?: LssEventResolvers<ContextType>;
  LssEventData?: LssEventDataResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Name?: NameResolvers<ContextType>;
  OtherBackup?: OtherBackupResolvers<ContextType>;
  Pagination?: PaginationResolvers<ContextType>;
  PartialName?: PartialNameResolvers<ContextType>;
  Person?: PersonResolvers<ContextType>;
  Pet?: PetResolvers<ContextType>;
  PracticeArea?: PracticeAreaResolvers<ContextType>;
  PracticeAreaLocation?: PracticeAreaLocationResolvers<ContextType>;
  PredictResponse?: PredictResponseResolvers<ContextType>;
  Property?: PropertyResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RefreshTokenResponse?: RefreshTokenResponseResolvers<ContextType>;
  RejectedModel?: RejectedModelResolvers<ContextType>;
  Repeat?: RepeatResolvers<ContextType>;
  RepeatService?: RepeatServiceResolvers<ContextType>;
  RequiredDocumentsResponse?: RequiredDocumentsResponseResolvers<ContextType>;
  ResiduaryPlan?: ResiduaryPlanResolvers<ContextType>;
  ResiduaryPlanCharities?: ResiduaryPlanCharitiesResolvers<ContextType>;
  ResiduaryPlanPersons?: ResiduaryPlanPersonsResolvers<ContextType>;
  ScheduledEventResponse?: ScheduledEventResponseResolvers<ContextType>;
  Service?: ServiceResolvers<ContextType>;
  ServiceExpertise?: ServiceExpertiseResolvers<ContextType>;
  ServicePreference?: ServicePreferenceResolvers<ContextType>;
  ServiceType?: ServiceTypeResolvers<ContextType>;
  ServingAttempt?: ServingAttemptResolvers<ContextType>;
  ServingTask?: ServingTaskResolvers<ContextType>;
  SpecificItem?: SpecificItemResolvers<ContextType>;
  StepsForClientDto?: StepsForClientDtoResolvers<ContextType>;
  SubmitDocumentsDataPointsResponse?: SubmitDocumentsDataPointsResponseResolvers<ContextType>;
  SubmitRepeatSupportRequestResponse?: SubmitRepeatSupportRequestResponseResolvers<ContextType>;
  SubmitUnavailableDocumentResponse?: SubmitUnavailableDocumentResponseResolvers<ContextType>;
  SyncInfo?: SyncInfoResolvers<ContextType>;
  UpdateResponse?: UpdateResponseResolvers<ContextType>;
  UpdateWillFormSteps?: UpdateWillFormStepsResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UsersResolverResponse?: UsersResolverResponseResolvers<ContextType>;
  Vehicle?: VehicleResolvers<ContextType>;
  VerifyOTPResponse?: VerifyOtpResponseResolvers<ContextType>;
  Visitor?: VisitorResolvers<ContextType>;
  VisitorStatus?: VisitorStatusResolvers<ContextType>;
  WillAdministratorInfo?: WillAdministratorInfoResolvers<ContextType>;
  WillBasicInfo?: WillBasicInfoResolvers<ContextType>;
  WillDataPoints?: WillDataPointsResolvers<ContextType>;
  WillFamilyInfo?: WillFamilyInfoResolvers<ContextType>;
  WillFormSteps?: WillFormStepsResolvers<ContextType>;
  WillPersonalData?: WillPersonalDataResolvers<ContextType>;
  WillResiduaryInfo?: WillResiduaryInfoResolvers<ContextType>;
  WillReviewInfo?: WillReviewInfoResolvers<ContextType>;
  WillStepInfo?: WillStepInfoResolvers<ContextType>;
  WillsAddress?: WillsAddressResolvers<ContextType>;
  sendSupportMsgResponse?: SendSupportMsgResponseResolvers<ContextType>;
};


export type HashMutationVariables = Exact<{
  request: HashRequest;
}>;


export type HashMutation = { __typename?: 'Mutation', hash: { __typename?: 'HashResponse', success: boolean, hash?: any | null | undefined, error?: HashError | null | undefined } };

export type CreateBookitRequestMutationVariables = Exact<{
  payload: CreateBookitRequestPayload;
}>;


export type CreateBookitRequestMutation = { __typename?: 'Mutation', createBookitRequest: { __typename?: 'BookitRequestResponse', id: string } };

export type GetAvailableSlotsQueryVariables = Exact<{
  payload: GetAvailableSlotsRequest;
}>;


export type GetAvailableSlotsQuery = { __typename?: 'Query', getAvailableSlots: Array<{ __typename?: 'AvailableSlot', start: string }> };

export type GetVisitorStatusQueryVariables = Exact<{
  visitorStatusInput: VisitorStatusInputReq;
}>;


export type GetVisitorStatusQuery = { __typename?: 'Query', getVisitorStatus: { __typename?: 'VisitorStatus', statusCode: VisitorStatusCode } };

export type HasAvailableSlotsQueryVariables = Exact<{
  payload: GetAvailableSlotsRequest;
}>;


export type HasAvailableSlotsQuery = { __typename?: 'Query', hasAvailableSlots: boolean };


export const HashDocument = gql`
    mutation Hash($request: HashRequest!) {
  hash(request: $request) {
    success
    hash
    error
  }
}
    `;
export type HashMutationFn = Apollo.MutationFunction<HashMutation, HashMutationVariables>;

/**
 * __useHashMutation__
 *
 * To run a mutation, you first call `useHashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hashMutation, { data, loading, error }] = useHashMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useHashMutation(baseOptions?: Apollo.MutationHookOptions<HashMutation, HashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HashMutation, HashMutationVariables>(HashDocument, options);
      }
export type HashMutationHookResult = ReturnType<typeof useHashMutation>;
export type HashMutationResult = Apollo.MutationResult<HashMutation>;
export type HashMutationOptions = Apollo.BaseMutationOptions<HashMutation, HashMutationVariables>;
export const CreateBookitRequestDocument = gql`
    mutation CreateBookitRequest($payload: CreateBookitRequestPayload!) {
  createBookitRequest(payload: $payload) {
    id
  }
}
    `;
export type CreateBookitRequestMutationFn = Apollo.MutationFunction<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>;

/**
 * __useCreateBookitRequestMutation__
 *
 * To run a mutation, you first call `useCreateBookitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookitRequestMutation, { data, loading, error }] = useCreateBookitRequestMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateBookitRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>(CreateBookitRequestDocument, options);
      }
export type CreateBookitRequestMutationHookResult = ReturnType<typeof useCreateBookitRequestMutation>;
export type CreateBookitRequestMutationResult = Apollo.MutationResult<CreateBookitRequestMutation>;
export type CreateBookitRequestMutationOptions = Apollo.BaseMutationOptions<CreateBookitRequestMutation, CreateBookitRequestMutationVariables>;
export const GetAvailableSlotsDocument = gql`
    query GetAvailableSlots($payload: GetAvailableSlotsRequest!) {
  getAvailableSlots(payload: $payload) {
    start
  }
}
    `;

/**
 * __useGetAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useGetAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableSlotsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetAvailableSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables> & ({ variables: GetAvailableSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
      }
export function useGetAvailableSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
        }
export function useGetAvailableSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>(GetAvailableSlotsDocument, options);
        }
export type GetAvailableSlotsQueryHookResult = ReturnType<typeof useGetAvailableSlotsQuery>;
export type GetAvailableSlotsLazyQueryHookResult = ReturnType<typeof useGetAvailableSlotsLazyQuery>;
export type GetAvailableSlotsSuspenseQueryHookResult = ReturnType<typeof useGetAvailableSlotsSuspenseQuery>;
export type GetAvailableSlotsQueryResult = Apollo.QueryResult<GetAvailableSlotsQuery, GetAvailableSlotsQueryVariables>;
export function refetchGetAvailableSlotsQuery(variables: GetAvailableSlotsQueryVariables) {
      return { query: GetAvailableSlotsDocument, variables: variables }
    }
export const GetVisitorStatusDocument = gql`
    query GetVisitorStatus($visitorStatusInput: VisitorStatusInputReq!) {
  getVisitorStatus(visitorStatusInput: $visitorStatusInput) {
    statusCode
  }
}
    `;

/**
 * __useGetVisitorStatusQuery__
 *
 * To run a query within a React component, call `useGetVisitorStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitorStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitorStatusQuery({
 *   variables: {
 *      visitorStatusInput: // value for 'visitorStatusInput'
 *   },
 * });
 */
export function useGetVisitorStatusQuery(baseOptions: Apollo.QueryHookOptions<GetVisitorStatusQuery, GetVisitorStatusQueryVariables> & ({ variables: GetVisitorStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>(GetVisitorStatusDocument, options);
      }
export function useGetVisitorStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>(GetVisitorStatusDocument, options);
        }
export function useGetVisitorStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>(GetVisitorStatusDocument, options);
        }
export type GetVisitorStatusQueryHookResult = ReturnType<typeof useGetVisitorStatusQuery>;
export type GetVisitorStatusLazyQueryHookResult = ReturnType<typeof useGetVisitorStatusLazyQuery>;
export type GetVisitorStatusSuspenseQueryHookResult = ReturnType<typeof useGetVisitorStatusSuspenseQuery>;
export type GetVisitorStatusQueryResult = Apollo.QueryResult<GetVisitorStatusQuery, GetVisitorStatusQueryVariables>;
export function refetchGetVisitorStatusQuery(variables: GetVisitorStatusQueryVariables) {
      return { query: GetVisitorStatusDocument, variables: variables }
    }
export const HasAvailableSlotsDocument = gql`
    query HasAvailableSlots($payload: GetAvailableSlotsRequest!) {
  hasAvailableSlots(payload: $payload)
}
    `;

/**
 * __useHasAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useHasAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAvailableSlotsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useHasAvailableSlotsQuery(baseOptions: Apollo.QueryHookOptions<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables> & ({ variables: HasAvailableSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>(HasAvailableSlotsDocument, options);
      }
export function useHasAvailableSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>(HasAvailableSlotsDocument, options);
        }
export function useHasAvailableSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>(HasAvailableSlotsDocument, options);
        }
export type HasAvailableSlotsQueryHookResult = ReturnType<typeof useHasAvailableSlotsQuery>;
export type HasAvailableSlotsLazyQueryHookResult = ReturnType<typeof useHasAvailableSlotsLazyQuery>;
export type HasAvailableSlotsSuspenseQueryHookResult = ReturnType<typeof useHasAvailableSlotsSuspenseQuery>;
export type HasAvailableSlotsQueryResult = Apollo.QueryResult<HasAvailableSlotsQuery, HasAvailableSlotsQueryVariables>;
export function refetchHasAvailableSlotsQuery(variables: HasAvailableSlotsQueryVariables) {
      return { query: HasAvailableSlotsDocument, variables: variables }
    }